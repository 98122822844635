export default class UserEntity {
  constructor () {
    this.id = undefined;
    this.firstName = '';
    this.firstNameKana = '';
    this.lastName = '';
    this.lastNameKana = '';
    this.name = undefined;
    this.nameKana = undefined;
    this.status = Constants.STATUS_NO_DATA;
    this.bed = {
      breath: Constants.EMPTY_VALUE,
      heart: Constants.EMPTY_VALUE,
      move: Constants.EMPTY_VALUE,
    };
    this.alerts = [];
    this.unconfirmedAlerts = [];
    this.addressPrefecture = Constants.PREFECTURES[0];
    this.addressMunicipality = '';
    this.addressOther = '';
    this.gateway = null;
    this.sensors = [];
    this.staffs = [];
    this.sensorSettings = [];
    this.haveBedSensor = false;
    this.haveLivingSensor = false;
    this.haveToiletSensor = false;
  }
}

if (Utils.isDebug()) {
  window.UserEntity = UserEntity;
}
