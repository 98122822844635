import { render, staticRenderFns } from "./HealthSummary.vue?vue&type=template&id=c1f183b6&scoped=true&"
import script from "./HealthSummary.vue?vue&type=script&lang=js&"
export * from "./HealthSummary.vue?vue&type=script&lang=js&"
import style0 from "./HealthSummary.vue?vue&type=style&index=0&id=c1f183b6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c1f183b6",
  null
  
)

component.options.__file = "HealthSummary.vue"
export default component.exports