let id = 0;

export default class AlertEntity {
  constructor () {
    this.id = id++;
    this.time = Constants.EMPTY_VALUE;
    this.type = Constants.EMPTY_VALUE;
    this.confirmed = false;
  }

  static clone (from) {
    let to = new AlertEntity();
    to.id = from.id;
    to.time = _.isUndefined(from.time) ? Constants.EMPTY_VALUE : from.time;
    to.type = _.isUndefined(from.type) ? Constants.EMPTY_VALUE : from.type;
    to.confirmed = _.isUndefined(from.confirmed) ? false : from.confirmed;
    return to;
  }
}

if (Utils.isDebug()) {
  window.AlertEntity = AlertEntity;
}
