<template>
  <b-form @submit.prevent="save">
    <div class="jsp-sensor-setting-screen container-fluid">
      <b-row><b-col cols="10">
        <b-row><b-col><b>設定変更ゲートウェイ選択</b></b-col></b-row>
        <b-row align-v="center">
          <b-col cols="2">利用者名</b-col>
          <b-col cols="3">
            <b-form-select v-model="selectUser" :options="userIds" size="sm" />
          </b-col>
          <b-col cols="2">ゲートウェイ</b-col>
          <b-col cols="3">
            <b-form-input v-model="gatewayId" size="sm" disabled />
          </b-col>
          <b-col cols="2">
            <Button variant="primary" type="submit" :loading="processing" :disabled="!isChange" >設定を保存</Button>
          </b-col>
        </b-row>
      </b-col></b-row>
      <hr>
      <b-row><b-col cols="12">
        <b-row><b-col><b>入浴判定</b></b-col></b-row>
        <b-row align-v="center" class="conf-area">
          <b-col cols="2">検知時間(秒)</b-col>
          <b-col cols="2"> 有効値：<font color="red">5 ～ 999</font></b-col>
          <b-col cols="2"><input class="form-control form-control-sm" v-model.number="setting.bathIn.detectSec" type="number" required min=5 max=999 /></b-col>
        </b-row>
        <b-row align-v="center" class="conf-area">
          <b-col cols="2">脈拍</b-col>
          <b-col cols="2"> 有効値：<font color="red">0 ～ 999</font></b-col>
          <b-col cols="2" class="form-inline">
            <input class="form-control form-control-sm form-inline-inner" v-model.number="setting.bathIn.apLow" type="number" required min=0 max=999 />
            <span class="extra">～</span>
            <input class="form-control form-control-sm form-inline-inner" v-model.number="setting.bathIn.apHigh" type="number" required :min='highMinValues.bathIn.ap' max=999 />
          </b-col>
        </b-row>
        <b-row align-v="center" class="conf-area">
          <b-col cols="2">体動閾値</b-col>
          <b-col cols="2"> 有効値：<font color="red">0 ～ 250</font></b-col>
          <b-col cols="2"><input class="form-control form-control-sm" v-model.number="setting.bathIn.mvThreshold" type="number" required min=0 max=250 /></b-col>
        </b-row>
      </b-col></b-row>
      <hr>
      <b-row><b-col cols="12">
        <b-row><b-col><b>出浴判定</b></b-col></b-row>
        <b-row>
          <b-col cols="4"></b-col>
          <b-col cols="2">ブロック１</b-col>
          <b-col cols="2">ブロック２</b-col>
          <b-col cols="2">ブロック３</b-col>
        </b-row>
        <b-row align-v="center" class="conf-area">
          <b-col cols="2">ブロック間隔(秒)</b-col>
          <b-col cols="2"> 有効値：<font color="red">1 ～ 99</font></b-col>
          <b-col cols="2"><input class="form-control form-control-sm" v-model.number="setting.bathOut.block1.intervalSec" type="number" required min=1 max=99 /></b-col>
          <b-col cols="2"><input class="form-control form-control-sm" v-model.number="setting.bathOut.block2.intervalSec" type="number" required min=1 max=99 /></b-col>
          <b-col cols="2"><input class="form-control form-control-sm" v-model.number="setting.bathOut.block3.intervalSec" type="number" required min=1 max=99 /></b-col>
        </b-row>
        <b-row align-v="center" class="conf-area">
          <b-col cols="2">データ数</b-col>
          <b-col cols="2"> 有効値：<font color="red">1 ～ 99</font></b-col>
          <b-col cols="2"><input class="form-control form-control-sm" v-model.number="setting.bathOut.block1.count" type="number" required min=1 :max='maxDataCounts.block1' /></b-col>
          <b-col cols="2"><input class="form-control form-control-sm" v-model.number="setting.bathOut.block2.count" type="number" required min=1 :max='maxDataCounts.block2' /></b-col>
          <b-col cols="2"><input class="form-control form-control-sm" v-model.number="setting.bathOut.block3.count" type="number" required min=1 :max='maxDataCounts.block3' /></b-col>
        </b-row>
        <b-row align-v="center" class="conf-area">
          <b-col cols="2">体動</b-col>
          <b-col cols="2"> 有効値：<font color="red">0 ～ 999</font></b-col>
          <b-col cols="2" class="form-inline">
            <input class="form-control form-control-sm form-inline-inner" v-model.number="setting.bathOut.block1.low" type="number" required min=0 max=999 />
            <span class="extra">～</span>
            <input class="form-control form-control-sm form-inline-inner" v-model.number="setting.bathOut.block1.high" type="number" required :min='highMinValues.bathOut.mv1' max=999 />
          </b-col>
          <b-col cols="2" class="form-inline">
            <input class="form-control form-control-sm form-inline-inner" v-model.number="setting.bathOut.block2.low" type="number" required min=0 max=999 />
            <span class="extra">～</span>
            <input class="form-control form-control-sm form-inline-inner" v-model.number="setting.bathOut.block2.high" type="number" required :min='highMinValues.bathOut.mv2' max=999 />
          </b-col>
          <b-col cols="2" class="form-inline">
            <input class="form-control form-control-sm form-inline-inner" v-model.number="setting.bathOut.block3.low" type="number" required min=0 max=999 />
            <span class="extra">～</span>
            <input class="form-control form-control-sm form-inline-inner" v-model.number="setting.bathOut.block3.high" type="number" required :min='highMinValues.bathOut.mv3' max=999 />
          </b-col>
        </b-row>
      </b-col></b-row>
    </div>
  </b-form>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters } from 'vuex';
import Button from '@/view/common/Button';
import BathSettingEntity from '@/model/entity/BathSettingEntity';
export default {
  name: 'SensorSettingScreen',
  components: {
    Button
  },
  data () {
    return {
      gatewayId: '-',
      selectUser: undefined,
      processing: false,
      setting: new BathSettingEntity()
    };
  },
  computed: {
    ...mapGetters('User', {
      users: 'users',
    }),
    ...mapGetters('Gateway', {
      bathSetting: 'bathSetting',
    }),
    userIds () {
      let users = [
        { value: undefined, text: '-' }
      ];
      _.each(this.users, (user) => {
        users.push({ value: user, text: user.name });
      });
      return users;
    },
    isChange () {
      if (_.isUndefined(this.bathSetting)) {
        return false;
      }
      return !_.isEqual(this.setting, this.bathSetting);
    },
    maxDataCounts () {
      let dataCounts = {
        block1: 99,
        block2: 99,
        block3: 99
      };
      if (!_.isUndefined(this.bathSetting)) {
        dataCounts.block1 = this.setting.bathOut.block1.intervalSec;
        dataCounts.block2 = this.setting.bathOut.block2.intervalSec;
        dataCounts.block3 = this.setting.bathOut.block3.intervalSec;
      }
      return dataCounts;
    },
    highMinValues () {
      // 上限の最低値
      let highMinValues = {
        bathIn: { ap: 0 },
        bathOut: { mv1: 0, mv2: 0, mv3: 0 }
      };
      if (!_.isUndefined(this.bathSetting)) {
        highMinValues.bathIn.ap = this.setting.bathIn.apLow;
        highMinValues.bathOut.mv1 = this.setting.bathOut.block1.low;
        highMinValues.bathOut.mv2 = this.setting.bathOut.block2.low;
        highMinValues.bathOut.mv3 = this.setting.bathOut.block3.low;
      }
      return highMinValues;
    }
  },
  watch: {
    selectUser (user) {
      if (_.isUndefined(user) || _.isNull(user.gateway)) {
        Vue.set(this, 'gatewayId', '-');
        this.clear();
      }
      else {
        Vue.set(this, 'gatewayId', user.gateway.id);
        this.selectGateway(user.gateway.id);
      }
    },
    bathSetting () {
      if (_.isUndefined(this.bathSetting)) {
        this.setting = new BathSettingEntity();
        return;
      }
      this.setting = BathSettingEntity.clone(this.bathSetting);
    }
  },
  methods: {
    ...mapActions('Gateway', [
      'selectGateway', 'clear', 'updateBathSetting'
    ]),
    save () {
      // 処理中フラグ
      this.processing = true;
      let bathSetting = BathSettingEntity.clone(this.setting);
      let payload = {
        target: { hgw: this.gatewayId },
        setting: JSON.stringify(bathSetting),
        callback: this.saveResult
      };
      this.updateBathSetting(payload);
    },
    saveResult (isSuccess, errorMessage) {
      Log.log(isSuccess, errorMessage);
      let self = this;
      if (isSuccess) {
        setTimeout(() => {
          self.processing = false;
          this.selectGateway(this.gatewayId);
          this.$notify('入浴/出浴判定を更新しました');
        }, 3000);
      }
      else {
        self.processing = false;
      }
    },
  },
  created () {
    this.clear();
  },
  mounted () {
  },
  beforeDestroy () {
  },
  destroyed () {
  }
};

</script>

<style scoped>
.jsp-sensor-setting-screen {
  position: relative;
  padding: 20px;
  width: 1100px;
  margin-left: initial !important;
}
.conf-area {
  margin-top: 4px;
}
.extra {
  text-align: center;
  min-width: 12%;
  white-space: nowrap;
  overflow: visible;
}
.form-inline-inner {
  width: 44%;
}

.form-inline {
  width: 100% !important;
}
</style>
