<template>
  <b-container class="jsp-manage-staff-screen">
    <Headline icon="staff">{{ isFloorMode ? 'フロア管理' : 'スタッフ管理' }}</Headline>

    <!-- スタッフモードの場合はフィルターを表示 -->
    <b-row align-h="center" v-if="isStaffMode">
      <b-col class="search-area" cols="11">
        <b-row align-v="center" class="text-center">
          <b-col cols="9" class="search-area-input">
            <b-row>
              <b-col class="label" cols="3">
                氏名(漢字/かな)：
              </b-col>
              <b-col cols="3">
                <input
                  class="form-control form-control-sm"
                  v-model="name"
                  placeholder="氏名(漢字/かな)"
                  maxlength="30"
                />
              </b-col>
              <b-col class="label" cols="3">
                利用者：
              </b-col>
              <b-col cols="3">
                <b-form-select v-model="user" :options="usersFilter" size="sm" />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="3">
            <Button icon="cross" size="xs" :frame="true" color="red" @click="clearFilter">
              フィルタクリア
            </Button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-row align-h="end" class="control-extra">
      <Button icon="plus" size="xs" :shadow="true" color="lightgreen" @click="add">
        新規追加
      </Button>
      <!-- <Button size="xs" :shadow="true" color="lightblue">CSV出力</Button>
      <Button size="xs" :shadow="true" color="lightblue">CSV入力</Button> -->
    </b-row>
    <b-row>
      <b-table bordered small :fields="fields" :items="filteredItems" :sort-compare="sortItems">
        <template slot="users" slot-scope="data">
          {{ data.item.users }} 名
        </template>
        <template slot="control" slot-scope="data">
          <Button size="xs" color="blue" icon="edit" @click="edit(data.item)" />
          <Button size="xs" color="red" icon="trash" @click="remove(data.item)" />
        </template>
      </b-table>
    </b-row>
    <EditStaffDialog ref="editStaffDialog" @initPassword="init" />
    <DeleteStaffDialog ref="deleteStaffDialog" />
    <InitPasswordDialog ref="initPasswordDialog" />
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Headline from '@/view/common/Headline';
import Button from '@/view/common/Button';
import EditStaffDialog from '@/view/manage-staff/EditStaffDialog';
import DeleteStaffDialog from '@/view/manage-staff/DeleteStaffDialog';
import InitPasswordDialog from '@/view/manage-staff/InitPasswordDialog';

const fieldsBase = [
  { key: 'name',    label: '氏名',                   sortable: true },
  { key: 'users',   label: '担当利用者数',           sortable: true },
  { key: 'mail',    label: 'ログインメールアドレス', sortable: true },
  { key: 'control', label: 'アクション',             class: 'control' },
];

const NO_FILTER = 'フィルタなし';
const NO_USER = { name: '担当利用者なし', nameKana: '' };

export default {
  components: {
    Headline,
    Button,
    EditStaffDialog,
    DeleteStaffDialog,
    InitPasswordDialog,
  },
  data () {
    return {
      fieldsBase,
      items: [],
      name: '',
      user: undefined,
    };
  },
  computed: {
    ...mapGetters('User', [
      'users',
    ]),
    ...mapGetters('Staff', [
      'staffs',
      'facilityMode',
    ]),
    isStaffMode () {
      return this.facilityMode === Constants.FACILITY_MODE_STAFF;
    },
    isFloorMode () {
      return this.facilityMode === Constants.FACILITY_MODE_FLOOR;
    },
    usersFilter () {
      let users = [
        { value: undefined, text: NO_FILTER },
        { value: NO_USER,   text: NO_USER.name },
      ];
      _.each(this.users, (user) => {
        users.push({ value: user, text: user.name });
      });
      return users;
    },
    fields () {
      let fields = this.fieldsBase;
      if (this.isFloorMode) {
        fields = [
          { key: 'name',    label: 'フロア',                 sortable: true },
          { key: 'users',   label: '利用者数',               sortable: true },
          { key: 'mail',    label: 'ログインメールアドレス', sortable: true },
          { key: 'control', label: 'アクション',             class: 'control' },
        ];
      }
      return fields;
    },
    filteredItems () {
      let filteredItems = _.clone(this.items);

      // 氏名フィルタ
      if (this.name !== '') {
        filteredItems = _.filter(filteredItems, (item) => {
          return item.name.indexOf(this.name) !== -1 || item.nameKana.indexOf(this.name) !== -1;
        });
      }

      // 利用者フィルタ
      if (_.isObject(this.user)) {
        if (this.user === NO_USER) {
          filteredItems = _.filter(filteredItems, (item) => {
            return item.users === 0;
          });
        }
        else {
          filteredItems = _.filter(filteredItems, (item) => {
            return item.staff.users.indexOf(this.user) !== -1;
          });
        }
      }

      return filteredItems;
    },
  },
  watch: {
    staffs () {
      this.updateItems();
    },
  },
  methods: {
    updateItems () {
      let items = [];

      _.each(this.staffs, (staff) => {
        // テーブル1行データの生成
        items.push({
          staff,
          name: staff.name,
          nameKana: staff.nameKana,
          users: staff.users.length,
          mail: staff.loginMail,
        });
      });

      // Vueプロパティ更新
      this.items = items;
    },
    sortItems (a, b, sortBy) {
      switch (sortBy) {
        case 'name':
          if (a.nameKana < b.nameKana) return -1;
          if (a.nameKana > b.nameKana) return 1;
          return 0;
        case 'users':
          return a.users - b.users;
        case 'mail':
          if (a.mail < b.mail) return -1;
          if (a.mail > b.mail) return 1;
          return 0;
        default:
          if (a[sortBy] < b[sortBy]) return -1;
          if (a[sortBy] > b[sortBy]) return 1;
          return 0;
      }
    },
    add () {
      this.$refs.editStaffDialog.show(null);
    },
    edit (item) {
      this.$refs.editStaffDialog.show(item.staff);
    },
    init (staff) {
      this.$refs.initPasswordDialog.show(staff);
    },
    remove (item) {
      this.$refs.deleteStaffDialog.show(item.staff);
    },
    clearFilter () {
      this.name = '';
      this.user = undefined;
    },
  },
  created () {
    this.updateItems();
  },
};
</script>

<style scoped>
.jsp-manage-staff-screen {
  padding-top: 16px;
  padding-bottom: 16px;
}

.search-area {
  border-radius: 16px;
  padding: 16px;
  margin: 16px 0;
  background-color: white;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
}
.label {
  line-height: 30px;
  text-align: right;
}
.search-area-button {
  padding: 0;
}

.control-extra {
  padding-right: 8px;
}
.control-extra > * + * {
  margin-left: 8px;
}

table {
  margin-top: 8px;
  background-color: white;
}
>>> th {
  background-color: #9de198;
}
>>> td:not(.control) {
  line-height: 28px;
}

.jsp-button + .jsp-button {
  margin-left: 12px;
}
</style>
