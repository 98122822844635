<template>
  <div class="jsp-user-detail-screen">
    <div class="area realtime">
      <div class="status" :class="statusColor">
        <UserName size="lg" :name="name">
          <Button icon="config" size="sm" color="gray"
            @click="showUserSettingDialog" />
        </UserName>
        <div class="status-area">
          <StatusIcon :user="user" size="xl" />
          <BathingTimeCounter :user="user" inline size="xl" />
          <div class="status-text-area">
            <StatusText :user="user" inline size="lg" />
          </div>
        </div>
        <template v-if="haveBedSensor">
          <RealtimeChart type="breath" :value="value.breath" :plot="plot.breath" :tick="tick" />
          <RealtimeChart type="heart" :value="value.heart" :plot="plot.heart" :tick="tick" />
          <RealtimeChart type="motion" :value="value.move" :plot="plot.move" :tick="tick" />
        </template>
      </div>
    </div>
    <div class="area main-area" >
      <div class="settings" :class="{ shown: isAlertSettingShown }" >
        <div class="setting-area">
          <AlertSetting ref="breathAlertSetting" type="breath" :setting="alertSetting" :foundSensor="foundBedSensor" v-bind:isChange.sync="breath.isChange" />
          <AlertSetting ref="heartAlertSetting" type="heart" :setting="alertSetting" :foundSensor="foundBedSensor" v-bind:isChange.sync="heart.isChange" />
          <AlertSetting ref="batheAlertSetting" type="bathe" :setting="alertSetting" :foundSensor="foundBedSensor" v-bind:isChange.sync="bathe.isChange" />
        </div>
        <div class="setting-area">
          <Button size="sm" @click="showAlertSettingUpdateDialog" :disabled="!(foundBedSensor && isAlertSettingChange)" >保存</Button>
        </div>
      </div>
      <div class="history" :class="{ shown: isAlertSettingShown }" >
        <div class="bathe-history">
          <div>
            <Headline icon="history" size="sm">入浴履歴</Headline>
          </div>
          <HistoryList
            :histories="histories"
            :selected="selectedHistory"
            :detail="detail"
            :setting="alertSetting"
            :down="isAlertSettingShown"
            @select="selectHistory"
          />
        </div>
        <div class="alert-history">
          <Headline icon="history" size="sm">アラート履歴</Headline>
          <AlertList :alerts="alerts" :down="isAlertSettingShown" />
        </div>
      </div>
    </div>
    <div class="reset-btn">
      <Button size="sm" @click.native="alertReset" :disabled="!foundBedSensor">
        アラートクリア
      </Button>
    </div>
    <div class="setting-btn">
        <Headline icon="alert" size="sm" @click.native="isAlertSettingShown = !isAlertSettingShown">
          アラート設定
        </Headline>
        <div class="control">
          <Icon :icon="alertToggleIcon" size="xs" color="gray" />
        </div>
    </div>
    <UserSettingDialog :user="user" ref="userSettingDialog" />
    <SensorSendConfirmDialog type='alert' ref="alertSettingUpdateDialog" />
    <SensorSendConfirmDialog type='reset' ref="sensorResetDialog" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment';

import Icon                from '@/view/common/Icon';
import Button              from '@/view/common/Button';
import UserName            from '@/view/common/UserName';
import StatusIcon          from '@/view/common/StatusIcon';
import StatusText          from '@/view/common/StatusText';
import Headline            from '@/view/common/Headline';
import BathingTimeCounter  from '@/view/common/BathingTimeCounter';
import RealtimeChart       from '@/view/user-detail/RealtimeChart';
import AlertList           from '@/view/user-detail/AlertList';
import HistoryList         from '@/view/user-detail/HistoryList';
import AlertSetting        from '@/view/user-detail/AlertSetting';
import UserSettingDialog  from '@/view/manage-user/UserSettingDialog';
import SensorSendConfirmDialog  from '@/view/common/SensorSendConfirmDialog';

export default {
  name: 'UserDetailScreen',
  components: {
    Icon,
    Button,
    UserName,
    StatusIcon,
    StatusText,
    RealtimeChart,
    Headline,
    BathingTimeCounter,
    AlertList,
    HistoryList,
    UserSettingDialog,
    AlertSetting,
    SensorSendConfirmDialog
  },
  data: function () {
    return {
      isAlertShown: false,
      isAlertSettingShown: false,
      tick: +new Date(),
      intervalId: false,
      breath: { isChange: false, },
      heart: { isChange: false },
      bathe: { isChange: false }
    };
  },
  computed: {
    ...mapGetters('State', [
      'ready',
    ]),
    ...mapGetters('User', {
      user: 'selectedUser',
    }),
    statusColor () {
      if (!_.isObject(this.user)) return '';
      if (this.user.alerts.length > 0) {
        return 'alerting';
      }
      switch (this.user.status) {
        case Constants.STATUS_NO_DATA: return 'no-data';
        case Constants.STATUS_LEAVE: return 'leave';
        case Constants.STATUS_LYING: return 'lying';
        case Constants.STATUS_MOVE: return 'move';
      }
      return '';
    },
    name () {
      if (_.isObject(this.user)) return this.user.name || '';
      return '';
    },
    value () {
      return {
        breath: _.isObject(this.user) ? this.user.bed.breath : Constants.EMPTY_VALUE,
        heart: _.isObject(this.user) ? this.user.bed.heart : Constants.EMPTY_VALUE,
        move: _.isObject(this.user) ? this.user.bed.move : Constants.EMPTY_VALUE,
      };
    },
    haveBedSensor () {
      if (!_.isObject(this.user)) return false;
      return this.user.haveBedSensor;
    },
    haveLivingSensor () {
      if (!_.isObject(this.user)) return false;
      return this.user.haveLivingSensor;
    },
    foundBedSensor () {
      // ベッドセンサが接続状態かどうか
      if (this.haveBedSensor) {
        switch (this.user.status) {
          case Constants.STATUS_NOT_FOUND_BED:
            return false;
        }
        return true;
      }
      return false;
    },
    ...mapGetters('Realtime', [
      'realtime',
    ]),
    plot () {
      return {
        breath: _.isObject(this.realtime) ? this.realtime.bed.breath : [],
        heart: _.isObject(this.realtime) ? this.realtime.bed.heart : [],
        move: _.isObject(this.realtime) ? this.realtime.bed.move : [],
      };
    },
    ...mapGetters('Record', {
      detail: 'detail',
      detail10: 'detail10',
      histories: 'histories',
      selectedHistory: 'selectedHistory',
      selectedMonth: 'selectedMonth',
      urlDate: 'urlDate',
    }),
    selectedDate () {
      if (_.isUndefined(this.selectedHistory)) return 0;
      return this.selectedHistory.date;
    },
    selectedDateStr () {
      if (_.isUndefined(this.selectedHistory)) return '';
      return moment(this.selectedHistory.date).format('YYYY/MM/DD');
    },
    alerts () {
      if (_.isUndefined(this.detail10)) return [];
      return this.detail10.alerts;
    },
    alertToggleIcon () {
      return this.isAlertSettingShown ? 'down' : 'up';
    },
    alertSetting () {
      if (_.isNull(this.user)) {
        return undefined;
      }
      let target = _.find(this.user.sensorSettings, (obj) => {
        return obj.code === Constants.SENSOR_BED;
      });
      if (_.isUndefined(target)) {
        return undefined;
      }
      return target.setting;
    },
    bedSensorInfo () {
      let target = _.find(this.user.sensors, (sensor) => {
        // ベッドセンサのみ設定できる
        return sensor.type === Constants.SENSOR_BED;
      });
      return target;
    },
    isAlertSettingChange () {
      // console.log(`breath ${this.breath.isChange}, heart ${this.heart.isChange}, bathe ${this.bathe.isChange}`)
      return this.breath.isChange || this.heart.isChange || this.bathe.isChange;
    },
  },
  watch: {
    ready () {
      if (this.ready) {
        this.initialize();
      }
    },
    user () {
      // 存在しない利用者の詳細画面を開いている場合は
      // 利用者一覧画面へ遷移する
      if (this.ready && _.isObject(this.user) === false) {
        Log.warn('Invalid user selected. Navigate to /list.');
        this.$router.push('/list');
      }
    },
    '$route' (to, from) {
      let date = +moment(to.params.date);
      this.setUrlDate(date);
    },
    isAlertSettingShown (n, o) {
      if (n && !o) {
        this.$refs.breathAlertSetting.reload();
        this.$refs.heartAlertSetting.reload();
        this.$refs.batheAlertSetting.reload();
      }
    }
  },
  methods: {
    initialize () {
      // ユーザーを指定
      this.selectUser(this.$route.params.userId);
      this.selectMonth(+moment(new Date()).startOf('date'));
      // 詳細データの通知を開始する
      this.startDetailNotification();
      // 未確認アラートを確認済みにするよう要求する
      this.setAlertConfirmed();
    },
    ...mapActions('User', [
      'startDetailNotification',
      'stopDetailNotification',
      'selectUser',
    ]),
    ...mapActions('Record', {
      selectMonth: 'selectMonth',
      selectHistoryAct: 'selectHistory',
      setAlertConfirmed: 'setAlertConfirmed',
      clearRecord: 'clear',
      setUrlDate: 'setUrlDate',
    }),
    ...mapActions('Realtime', {
      clearRealtime: 'clear',
    }),
    showUserSettingDialog () {
      this.$refs.userSettingDialog.show(this.user);
    },
    showAlertSettingUpdateDialog () {
      // 押せる場合はベッドセンサがある状態
      if (_.isUndefined(this.bedSensorInfo)) {
        return;
      }
      let diff = { ...this.$refs.breathAlertSetting.diff, ...this.$refs.heartAlertSetting.diff, ...this.$refs.batheAlertSetting.diff };
      // 呼吸、脈拍、入浴時間からdiffを取得
      let data = {
        userId: this.user.id,
        gatewayId: this.user.gateway.id,
        sensorId: this.bedSensorInfo.id,
        setting: diff
      };
      this.$refs.alertSettingUpdateDialog.show(data);
    },
    showExpandChartDialog () {
      this.$refs.expandChartDialog.show(
        this.user,
        this.selectedHistory,
        this.detail,
      );
    },
    updateTick () {
      this.tick = +new Date();
    },
    selectHistory (history) {
      let urlDate = moment(history.date).format('YYYY-MM-DD');
      let userId = this.$route.params.userId;
      this.$router.push(`/detail/${userId}/${urlDate}`);
      this.setUrlDate(urlDate);
      this.selectHistoryAct(history);
    },
    alertReset () {
      let payload = {
        userId: this.user.id,
        gatewayId: this.user.gateway.id,
        sensorId: this.bedSensorInfo.id,
      };
      this.$refs.sensorResetDialog.show(payload);
    },
  },
  created () {
    this.initialize();
    // URLに日付が指定されていれば初期データとして設定する
    if (_.isUndefined(this.$route.params.date)) return;
    let date = +moment(this.$route.params.date);
    this.setUrlDate(date);
  },
  mounted () {
    this.intervalId = setInterval(this.updateTick, 200);
  },
  beforeDestroy () {
    clearInterval(this.intervalId);
  },
  destroyed () {
    this.stopDetailNotification();
    this.selectUser(null);
    this.setUrlDate(undefined);
    this.clearRealtime();
    this.clearRecord();
  },
};
</script>

<style scoped>
/* 全体のサイズ・配置 */
.jsp-user-detail-screen {
  position: relative;
  display: flex;
  flex-direction: row;
  min-height: 100%;
  padding: 16px 12px;
}
/* .jsp-user-detail-screen > * {
  height: 100%;
} */
.area {
  position: relative;
}
.area:first-child {
  min-width: 300px;
  max-width: 300px;
}
.area:nth-child(2) {
  flex: 1;
  max-width: calc(100% - 300px);
}
.area > * {
  position: relative;
}
.area:first-child > *:first-child {
  padding: 0 8px 56px 0;
}
.area:nth-child(2) > *:first-child {
  padding: 0 0 16px 8px;
}
.area:nth-child(2) > *:last-child {
  padding: 0px 0 16px 0px;
}

/* 区切り線 */
.history:before,
.jsp-user-detail-screen:before,
.area.main-area > .setting:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-color: gray;
}
.jsp-user-detail-screen:before {
  left: 312px;
  top: 16px;
  bottom: 0px;
  border-width: 0 1px 0 0;
}
.area.main-area > .setting:before {
  left: 16px;
  right: 0;
  bottom: 0;
  border-width: 0 0 1px 0;
}
.area.main-area(.down) {
  min-height: calc(100% - 200px);
  max-height: 0px;
  padding-top: 0px;
  transition: max-height 300ms ease;
}
.area.main-area:not(.down) {
}
.area.main-area {
  min-height: 100%;
}
/* リアルタイム情報概要 */
.status-area {
  margin: 4px 0 8px;
  text-align: center;
}
.jsp-realtime-chart + .jsp-realtime-chart {
  margin-top: 8px;
}

/* アラート設定 */
.setting > div:first-child {
  margin-bottom: 8px;
}
.setting > div > .jsp-headline {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}
.setting > div > .date {
  display: inline-block;
  vertical-align: middle;
  width: 84px;
  font-size: 16px;
  text-align: center;
}
.setting > div > .jsp-button {
  vertical-align: middle;
}
.jsp-detail-chart + .jsp-detail-chart {
  margin-top: 12px;
}

/* 過去データ */
.history {
  display: flex;
  flex-direction: row;
  height: calc(100vh - 73px);
  transition: height 300ms ease;
}
.history:before {
  left: 50%;
  top: 0px;
  bottom: -16px;
  border-width: 0 1px 0 0;
}
.history .jsp-headline {
  display: inline-block;
  vertical-align: middle;
  margin-right: 20px;
}
.history select {
  vertical-align: middle;
  width: 120px;
}
.history .jsp-button {
  vertical-align: middle;
}
.jsp-history-list {
  flex: 1;
  padding: 0px 8px 0px 8px;
}
.setting-btn {
  position: fixed;
  right: 0;
  top: 40px;
  width: 165px;
  display: flex;
  flex-direction: column;
  height: auto;
}
.setting-btn > .jsp-headline {
  position: relative;
  padding: 4px 0px 8px 8px;
  border-radius: 0 0 0 4px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.25);
  color: #646464;
  background-color: #ceffc9;
  overflow: hidden;
}
.setting-btn > .jsp-headline:hover::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
/* アラート設定 */
.settings {
  transition: height 300ms ease;
  height: 220px;
  border-bottom: solid 1px;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
}
/* アラート履歴 */
.reset-btn {
  position: fixed;
  left: 80px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 150px;
  height: auto;
}
.reset-btn > .jsp-button {
  color: white;
  background-color: #ff9292;
  border-radius: 4px 4px 0 0;
  box-shadow: 2px 0px 8px 0px rgba(0, 0, 0, 0.25);
}

.reset-btn > .jsp-button:hover:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
.count {
  padding: 2px 8px;
  border: solid 2px white;
  border-radius: 100px;
  margin-left: 8px;
  font-size: 80%;
}
/* アラート設定を非表示 */
.settings:not(.shown) {
  height: 0px;
  overflow-y: hidden;
  padding-bottom: 0px !important;
  border-bottom: 0px;
}

.control {
  position: absolute !important;
  top: 4px;
  right: 8px;
}
.status > .jsp-user-name {
  display: flex;
  align-items: flex-end;
}
.status > .jsp-user-name > .jsp-button {
  background-color: transparent;
}
.settings > .setting-area  {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.settings > .setting-area:first-child {
  margin-bottom: 16px;
}
.setting-area > .jsp-alert-setting:nth-child(2) {
  margin-left: 4px;
}
.setting-area > .jsp-alert-setting:last-child {
  margin-left: 4px;
}
.setting-area > .jsp-button {
  width: 100px;
}
.history > * {
  padding: 0px 0px 0px 1px;
  min-width: 50%;
  max-width: 50%;
}
.history.shown {
  height: calc(100vh - 293px);
}
.bathe-history > * {
  margin-bottom: 8px;
}
.alert-history > * {
  margin-bottom: 8px;
}
.bathe-history .jsp-headline {
  position: relative;
  padding: 4px 8px 4px 8px;
  background-color: #bbe8bc;
  width: 130px;
  height: 35px;
}
.bathe-history .jsp-headline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 130px;
  right: -20px;
  border-color: #bbe8bc transparent transparent transparent;
  border-width: 35px 20px 20px 0px;
  border-style: solid;
}
.alert-history .jsp-headline {
  position: relative;
  padding: 4px 8px 4px 8px;
  background-color: #ffc2c2;
  width: 150px;
  height: 35px;
}
.alert-history .jsp-headline:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 150px;
  right: -20px;
  border-color: #ffc2c2 transparent transparent transparent;
  border-width: 35px 20px 20px 0px;
  border-style: solid;
}
</style>
