<template>
  <div class="jsp-user-list-screen">
    <div class="jsp-user-group-area">
      <UserGroup :group="viewGroup" />
    </div>
    <div class="jsp-user-group-nav">
      <b-pagination
       align="center"
       v-model="currentPage"
       :total-rows="totalPage"
       :per-page="1"
       hide-goto-end-buttons ></b-pagination>
     </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserGroup from '@/view/user-list/UserGroup';

export default {
  name: 'UserListScreen',
  components: {
    UserGroup,
  },
  data: function () {
    return {
      onoff: true,
      currentPage: 1,
      autoPagingTask: null,
      opMonitorTask: null,
    };
  },
  computed: {
    ...mapGetters('User', [
      'groups',
    ]),
    ...mapGetters('Staff', [
      'listGroupMode',
      'loginStaff',
      'facilityMode',
    ]),
    listGroupModes () {
      let staffText = '担当スタッフ';
      if (this.facilityMode === Constants.FACILITY_MODE_FLOOR) {
        staffText = 'フロア別';
      }
      let listGroupModes = [
        { code: Constants.LIST_GROUP_MODE_NONE, text: 'なし' },
        { code: Constants.LIST_GROUP_MODE_STAFF, text: staffText },
      ];
      return listGroupModes;
    },
    viewGroup () {
      return this.groups[this.currentPage - 1];
    },
    totalPage () {
      return this.groups.length;
    }
  },
  methods: {
    ...mapActions('Staff', [
      'updateListGroupMode',
    ]),
    startOpeMonitor () {
      // 操作を検知する、無操作状態を判定する
      let count = 0;
      let self = this;
      this.opeMnitorTask = setInterval(function () {
        count++;
        if (30 <= count) {
          // 30秒を超えたなら
          self.startAutoPaging();
        }
        else {
          self.stopAutoPaging();
        }
      }, 1000);
      document.body.onmousedown = function () { count = 0; };
      document.body.onkeydown   = function () { count = 0; };
      document.body.onmousemove = function () { count = 0; };
    },
    stopOpeMonitor () {
      document.body.onmousedown = null;
      document.body.onkeydown = null;
      document.body.onmousemove = null;
      if (!_.isNull(this.opeMnitorTask)) {
        clearInterval(this.opeMnitorTask);
        this.opeMnitorTask = null;
      }
    },
    startAutoPaging () {
      let self = this;
      if (_.isNull(this.autoPagingTask)) {
        self.changeNextPage();
        this.autoPagingTask = setInterval(function () {
          self.changeNextPage();
        }, 5000);
      }
    },
    stopAutoPaging () {
      if (!_.isNull(this.autoPagingTask)) {
        clearInterval(this.autoPagingTask);
        this.autoPagingTask = null;
      }
    },
    changeNextPage () {
      if (this.totalPage > this.currentPage) {
        this.currentPage++;
      }
      else {
        this.currentPage = 1;
      }
    }
  },
  watch: {
  },
  created () {
    this.startOpeMonitor();
  },
  destroyed () {
    this.stopAutoPaging();
    this.stopOpeMonitor();
  }
};
</script>

<style scoped>
.jsp-user-list-screen {
  padding: 8px 0px;
}
.jsp-user-list-screen > * + * {
  margin-top: 2px;
}
.jsp-headline {
  margin-left: -20px;
}
.jsp-user-group-area {
}
.jsp-user-group-nav {
}
</style>
<style>
.page-item.active .page-link {
  background-color: #61c359 !important;
  color: #fff !important;
  border-color: #61c359 !important;
}
.page-link {
  color: #61c359 !important;
}
.page-link:focus {
  box-shadow: 0 0 0 0.2rem rgba(66, 255, 0, 0.25) !important;
}
</style>
