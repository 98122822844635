export default class HistoryEntity {
  constructor () {
    this.date = Constants.EMPTY_VALUE;
    this.month = '';
    this.batheTime = 0;
    this.batheFlg = false;
    this.alert = false;
  }
}

if (Utils.isDebug()) {
  window.HistoryEntity = HistoryEntity;
}
