<template>
  <VueCustomScrollbar class="jsp-alert-list" :class="{ down: down }" :settings="{ wheelPropagation: false }">
    <AlertListItem v-for="alert in alerts" :key="alert.id"
      :alert="alert" />
    <div v-if="alerts.length === 0" class="empty-state">
      アラートが発生していません。<br>
      発生したアラートはここへ表示されます。
    </div>
  </VueCustomScrollbar>
</template>

<script>
import VueCustomScrollbar from 'vue-custom-scrollbar';
import AlertListItem from '@/view/user-detail/AlertListItem';

export default {
  components: {
    VueCustomScrollbar,
    AlertListItem,
  },
  data () {
    return {
      scrollSettings: {
        wheelPropagation: false,
      },
    };
  },
  props: {
    alerts: { type: Array, require: true },
    down: { type: Boolean, default: false }
  },
};
</script>

<style scoped>
.jsp-alert-list {
  position: relative;
  height: calc(100vh - 110px);
  padding: 0px 8px 0px 8px;
  transition: height 300ms ease;
}
.jsp-alert-list.down {
  height: calc(100vh - 330px);
}
.empty-state {
  font-weight: bold;
  color: #61c359;
}
.jsp-alert-list-item + .jsp-alert-list-item {
  margin-top: 4px;
}
</style>
