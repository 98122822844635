import { render, staticRenderFns } from "./HistoryList.vue?vue&type=template&id=416e89de&scoped=true&"
import script from "./HistoryList.vue?vue&type=script&lang=js&"
export * from "./HistoryList.vue?vue&type=script&lang=js&"
import style0 from "./HistoryList.vue?vue&type=style&index=0&id=416e89de&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "416e89de",
  null
  
)

component.options.__file = "HistoryList.vue"
export default component.exports