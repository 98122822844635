import { render, staticRenderFns } from "./SensorSettingTabItem.vue?vue&type=template&id=13ca0e04&scoped=true&"
import script from "./SensorSettingTabItem.vue?vue&type=script&lang=js&"
export * from "./SensorSettingTabItem.vue?vue&type=script&lang=js&"
import style0 from "./SensorSettingTabItem.vue?vue&type=style&index=0&id=13ca0e04&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ca0e04",
  null
  
)

component.options.__file = "SensorSettingTabItem.vue"
export default component.exports