<template>
  <div id="app" class="app-container container-fluid">
    <Header v-if="needHeader" />
    <div class="main-container">
      <VueCustomScrollbar class="main-content">
        <router-view />
      </VueCustomScrollbar>
    </div>
    <div id="popover-error"></div>
    <notifications position="right bottom" />
    <Loader fill :loading="loading">
      サーバーと通信中...
    </Loader>
    <AlertDialog />
    <AlertAudio v-if="ready" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from '@/view/header/Header';
import Loader from '@/view/common/Loader';
import AlertDialog from '@/view/common/AlertDialog';
import AlertAudio from '@/view/common/AlertAudio';
import VueCustomScrollbar from 'vue-custom-scrollbar';

export default {
  components: {
    Header,
    Loader,
    AlertDialog,
    AlertAudio,
    VueCustomScrollbar
  },
  computed: {
    needHeader () {
      return !this.$route.meta.noHeader;
    },
    ...mapGetters('State', [
      'loading',
      'ready',
    ]),
  },
};
</script>

<style scoped>
.app-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0;
  /* background-color: #ecfdea; */
  background-color: #f3fff2;
}
.main-container {
  position: relative;
  flex: 1;
}
.main-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: hidden;
}
.app-container > .jsp-loader {
  z-index: 9999;
}
</style>

<style>
* {
  padding: 0;
  margin: 0;
  font-family: 'osaka', helvetica, 'Hiragino Kaku Gothic ProN', 'Yu Gothic UI', Meiryo, sans-serif;
}
*:not(input):not(textarea) {
  user-select: none;
}
html, body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* セレクトボックスの無効アイテム */
option[disabled] {
  color: lightgray;
}

/* 入力エラーなinput要素 */
.form-control.is-invalid {
  padding-right: 0.5rem;
  background-image: none;
}

/* ユーザー一覧／リアルタイムのアイコンSVG・文字カラー */
.status-no-data,
.status-no-data *,
.status-stop,
.status-stop *,
.status-leave,
.status-leave *,
.status-no-active,
.status-no-active * {
  fill: #9b9b9b !important;
  color: #9b9b9b !important;
}
.status-lying,
.status-lying * {
  fill: #498fc5 !important;
  color: #498fc5 !important;
}
.status-move,
.status-move * {
  fill: #ff963a !important;
  color: #ff963a !important;
}
.status-active,
.status-active * {
  fill: #26d620 !important;
  color: #26d620 !important;
}
.status-alerting,
.status-alerting * {
  fill: #d75251 !important;
  color: #d75251 !important;
}
.status-warning,
.status-warning * {
  fill: #ffbb00 !important;
  color: #ffbb00 !important;
}

/* リアルタイム／詳細グラフのSVGカラー */
.jsp-realtime-breath,
.c3-line-呼吸 {
  stroke: #415492 !important;
  color: #415492;
}
.jsp-realtime-heart,
.c3-line-脈拍 {
  stroke: #d75251 !important;
  color: #d75251;
}
.jsp-realtime-motion,
.c3-line-体動 {
  stroke: #ffbb00 !important;
  color: #ffbb00;
}
.c3-line-温度 {
  stroke: #ff7c53 !important;
  color: #ff7c53;
}
.c3-line-湿度 {
  stroke: #67c7d4 !important;
  color: #67c7d4;
}
.c3-line-照度 {
  stroke: #ffaa00 !important;
  color: #ffaa00;
}
.c3-tooltip-name--呼吸 > .name > span {
  background-color: #415492 !important;
}
.c3-tooltip-name--脈拍 > .name > span {
  background-color: #d75251 !important;
}
.c3-tooltip-name--温度 > .name > span {
  background-color: #ff7c53 !important;
}
.c3-tooltip-name--湿度 > .name > span {
  background-color: #67c7d4 !important;
}
.c3-tooltip-name--照度 > .name > span {
  background-color: #ffaa00 !important;
}

/* アラート履歴の背景カラー */
.jsp-alert-list-item.leave       { background-color: #d4d4d4 !important; }
.jsp-alert-list-item.long-lying  { background-color: #9ADBEE !important; }
.jsp-alert-list-item.breath      { background-color: #cecefc !important; }
.jsp-alert-list-item.heart       { background-color: #ffc2c2 !important; }
.jsp-alert-list-item.move        { background-color: #ffd180 !important; }
.jsp-alert-list-item.temperature { background-color: #ffcfc0 !important; }
.jsp-alert-list-item.humidity    { background-color: #c8ebef !important; }
.jsp-alert-list-item.heatstroke  { background-color: #ffcfc0 !important; }
.jsp-alert-list-item.toilet      { background-color: #ecc8fe !important; }
.jsp-alert-list-item.long-bathe  { background-color: #b9efc5 !important; }
.jsp-alert-list-item.leave      > .jsp-icon { color: #6A6A6A !important; }
.jsp-alert-list-item.long-lying > .jsp-icon { color: #00A7D6 !important; }
.jsp-alert-list-item.breath     > .jsp-icon { color: #7878FF !important; }
.jsp-alert-list-item.heart      > .jsp-icon { color: #FF6767 !important; }
.jsp-alert-list-item.move       > .jsp-icon { color: #FF8800 !important; }
.jsp-alert-list-item.long-bathe > .jsp-icon { color: #4ab162 !important; }
.jsp-alert-list-item.emergency { background-color: #ff5a5a !important; color: white !important; }
/* 過去データSVGカラー */
.history-no-data {
  fill: #c0c0c0;
  color: #c0c0c0;
}
.history-leave {
  fill: #ffffff;
  color: #ffffff;
}
.history-lying {
  fill: #89e985;
  color: #89e985;
}
.history-alert {
  fill: #ffb1b1;
  color: #ffb1b1;
}

/* アラート設定カラー */
.color-leave *,
.color-long-leave * {
  fill: #707070;
  color: #707070;
}
.color-long-lying * {
  fill: #498fc5;
  color: #498fc5;
}
.color-breath * {
  fill: #415492;
  color: #415492;
}
.color-heart * {
  fill: #d75251;
  color: #d75251;
}
.color-move * {
  fill: #ff963a;
  color: #ff963a;
}


/* 入力エラーポップオーバー */
.popover {
  max-width: 360px;
}
#popover-error > .popover {
  background-color: red;
}
#popover-error > .bs-popover-top > .arrow::after {
  border-top-color: red;
}
#popover-error > .bs-popover-right > .arrow::after {
  border-right-color: red;
}
#popover-error > .bs-popover-bottom > .arrow::after {
  border-bottom-color: red;
}
#popover-error > .bs-popover-left > .arrow::after {
  border-left-color: red;
}
#popover-error > .popover > .popover-body {
  color: white;
}

/* ダイアログ */
.v--modal-box {
  padding: 32px 48px !important;
  color: #3b3b3b !important;
}
.v--modal-background-click {
  min-height: auto !important;
  height: 100% !important;
}

/* vue-custom-scrollbar */
#app .ps__rail-x {
  z-index: 1000;
  height: 13px;
}
#app .ps__rail-y {
  z-index: 1000;
  width: 13px;
}
#app .ps:hover > .ps__rail-x,
#app .ps:hover > .ps__rail-y,
#app .ps--focus > .ps__rail-x,
#app .ps--focus > .ps__rail-y,
#app .ps--scrolling-x > .ps__rail-x,
#app .ps--scrolling-y > .ps__rail-y {
  opacity: 0.3;
}
#app .ps .ps__rail-x:hover,
#app .ps .ps__rail-y:hover,
#app .ps .ps__rail-x:focus,
#app .ps .ps__rail-y:focus,
#app .ps .ps__rail-x.ps--clicking,
#app .ps .ps__rail-y.ps--clicking {
  background-color: #c6e9c3;
  opacity: 0.6;
}
#app .ps__thumb-x,
#app .ps__thumb-y {
  background-color: #61c359;
}
#app .ps__thumb-x {
  height: 5px;
}
#app .ps__thumb-y {
  width: 5px;
}
#app .ps__rail-x:hover > .ps__thumb-x,
#app .ps__rail-x:focus > .ps__thumb-x,
#app .ps__rail-x.ps--clicking .ps__thumb-x {
  height: 9px;
}
#app .ps__rail-y:hover > .ps__thumb-y,
#app .ps__rail-y:focus > .ps__thumb-y,
#app .ps__rail-y.ps--clicking .ps__thumb-y {
  width: 9px;
}
</style>
