<template>
  <b-form class="jsp-tab-content" @submit.prevent="apply">
    <b-container>
        <!--
      ・氏名(漢字) ... 内部データは「名」
      ・氏名(かな) ... 内部データは「めい」
      -->
      <b-row align-v="center">
        <b-col cols="3" class="label">氏名(漢字)</b-col>
        <b-col cols="9" class="input">
          <input
            class="form-control form-control-sm"
            v-model="editUser.firstName"
            required
            maxlength="10"
          />
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col cols="3" class="label">氏名(かな)</b-col>
        <b-col cols="9" class="input">
          <input
            class="form-control form-control-sm"
            v-model="editUser.firstNameKana"
            required
            maxlength="20"
          />
        </b-col>
      </b-row>

        <!--
        ・氏名(漢字) ... 内部データは「名」
        ・氏名(かな) ... 内部データは「めい」
        ・フロア ... 内部データは「担当スタッフ」
        ・部屋名 ... 内部データは「せい」
        -->
      <template >
        <b-row align-v="center">
          <b-col cols="3" class="label">フロア</b-col>
          <b-col cols="9" class="input">
            <input
              autocomplete="on"
              list="floor"
              class="form-control form-control-sm"
              size="sm"
              v-model="editUser.lastNameKana"
              maxlength="50"
              required
            />
            <datalist id="floor">
              <template v-for="floor in floorsTemp">
                <option :value="floor" />
              </template>
            </datalist>
          </b-col>
        </b-row>
      </template>
      <template >
        <b-row align-v="center">
          <b-col cols="3" class="label">部屋名</b-col>
          <b-col cols="9" class="input">
            <input
              class="form-control form-control-sm"
              v-model="editUser.lastName"
              maxlength="50"
              required
            />
          </b-col>
        </b-row>
      </template>
      <template >
        <b-row align-h="end" class="buttons">
          <Button color="blue" type="submit" :loading="processing">{{ applyLabel }}</Button>
          <Button color="blue" :frame="true" @click="hide" :disabled="processing">キャンセル</Button>
        </b-row>
      </template>
    </b-container>
  </b-form>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserEntity from '@/model/entity/UserEntity';
import Button from '@/view/common/Button';

export default {
  components: {
    Button,
  },
  props: {
    user: { type: UserEntity, require: true },
  },
  data () {
    return {
      editUser: {},
      addingSensor: null,
      processing: false,
      floorsTemp: []
    };
  },
  computed: {
    ...mapGetters('User', [
      'floors',
    ]),
    applyLabel () {
      return '利用者情報登録';
    },
  },
  watch: {
    floors (n, o) {
      if (!_.isEqual(n, o)) {
        this.floorsTemp.length = 0;
        this.floorsTemp.splice(this.floorsTemp.length, 0, ...this.floors);
      }
    }
  },
  methods: {
    hide () {
      this.$modal.hide('user-setting-dialog');
    },
    apply (e) {
      // 処理中フラグ
      this.processing = true;

      // 利用者追加・変更処理
      this.applyUser({ user: this.editUser,  callback: this.applyResult });
    },
    applyResult (isSuccess, errorMessage) {
      Log.log(isSuccess, errorMessage);
      this.processing = false;

      if (isSuccess) {
        this.hide();
        this.$notify(`利用者情報を更新しました`);
      }
    },
    ...mapActions('User', [
      'applyUser',
    ]),
  },
  created () {
    this.editUser = _.clone(this.user);
    this.floorsTemp = _.clone(this.floors);
  }
};
</script>

<style scoped>
.jsp-tab-content {
  display: flex;
  height: 370px;
  padding: 24px;
  align-items: center;
}
.jsp-headline {
  padding-bottom: 3px;
  border-bottom: solid 1px dimgray;
}

.container {
  padding-top: 20px;
  padding-bottom: 20px;
}
.container + .jsp-headline {
  margin-top: 12px;
}
.row + .row {
  margin-top: 12px;
}
.label {
  font-size: 15px;
}
.buttons {
  margin-right: 0px !important;
}
.buttons > .jsp-button {
  min-width: 100px;
  margin-left: 8px;
}
</style>
