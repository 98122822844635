<template>
  <Dialog name="user-setting-dialog" class="jsp-user-setting-dialog"
    :width="880"
    height="auto"
    :processing="processing"
  >
    <b-container>
      <b-tabs>
        <b-tab>
          <template slot="title">
            <span class="tab-title user-tab" id="sensor" >
              <Icon icon="user" />
              {{ userTabTitle }}
            </span>
          </template>
          <UserSettingTabItem :user="user" />
        </b-tab>
        <b-tab :disabled='!haveBedSensor' >
          <template slot="title">
            <span class="tab-title sensor-tab" id="user" >
              <Icon icon="bathe" />
              {{ sensorTabTitle }}
            </span>
          </template>
          <SensorSettingTabItem :user="user" />
        </b-tab>
      </b-tabs>
    </b-container>
  </Dialog>
</template>

<script>
import Dialog from '@/view/common/Dialog';
import UserEntity from '@/model/entity/UserEntity';
import Icon from '@/view/common/Icon';
import SensorSettingTabItem from '@/view/manage-user/tabs/SensorSettingTabItem';
import UserSettingTabItem from '@/view/manage-user/tabs/UserSettingTabItem';
export default {
  components: {
    Dialog,
    Icon,
    SensorSettingTabItem,
    UserSettingTabItem
  },
  props: {
    user: { type: UserEntity, require: true },
  },
  data () {
    return {
      sensorTabTitle: 'センサー設定',
      userTabTitle: '利用者情報設定',
      processing: false,
    };
  },
  computed: {
    haveBedSensor () {
      if (!_.isObject(this.user)) return false;
      return this.user.haveBedSensor;
    },
  },
  methods: {
    show () {
      this.$modal.show('user-setting-dialog');
    },
    hide () {
      this.$modal.hide('user-setting-dialog');
    },
    sensorName (code) {
      return Constants.SENSOR_NAME[code] || '';
    },
    shouldShowNurseCallToMail (sensor) {
      if (Utils.getShowNurseCallToMail() === false) return false;
      return sensor.code === Constants.SENSOR_BED && _.isObject(sensor.nurseCall);
    },
  },
};
</script>

<style scoped>
.jsp-user-setting-dialog >>> .v--modal {
  background-color: #ffffff;
}
.jsp-user-setting-dialog >>> .v--modal-box {
  padding: 32px 22px !important;
}
.jsp-headline {
  padding-bottom: 3px;
  border-bottom: solid 1px dimgray;
}
.user-name {
  font-size: 80%;
}

.container {
  padding-top: 20px;
  padding-bottom: 20px;
}
>>> .nav-tabs {
  outline: none !important;
}
>>> .tab-content {
  position: relative;
  height: 370px;
  border: none !important;
  background-color: #d7f8d5;
  overflow-y: auto;
  box-shadow: -6px 6px 6px -2px rgba(27, 33, 58, 0.4);
  z-index: 200;
}
.jsp-user-setting-dialog >>> .nav-link {
  border: none !important;
}
.jsp-user-setting-dialog >>> .nav-tabs {
  border: none !important;
}
.jsp-user-setting-dialog >>> .nav-link.active {
  position: relative;
  background-color: #d7f8d5;
  box-shadow: -4px 7px 6px 0px rgba(27, 33, 58, 0.4);
  z-index: 100;
}
.settings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly !important;
  justify-content: space-between;
  align-content: flex-start;
  align-items: flex-start;
  padding: 10px 0 0 10px;
}
.invisible {
  visibility: none;
}

.tab-title {
  align-items: center;
  display: flex;
  font-weight: bold;
  font-size: 18px;
  color: #646464 !important;
}
.tab-title .jsp-icon {
  padding-right: 4px;
}
.tab-title .jsp-icon >>> * {
  fill: #646464 !important;
  color: #646464 !important;
}
</style>
