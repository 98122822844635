<template>
  <div class="jsp-alert-setting" :class="type">
    <div class='jsp-alert-title-area' :class="type">
      <Headline :icon="type" size="md" inline>
        {{ titleText }}
        <Icon :id="id" class="help" color="gray" size="xs" icon="question" />
        <b-tooltip :target="id">{{ tooltip }}</b-tooltip>
        <SwitchButton variant="primary" on-label="ON" off-label="OFF" v-model="onoff"
         :disabled="!(foundSensor && haveSensorSetting)" />
      </Headline>
    </div>
    <div class='config-area'>
      <div class="content range" v-if="rangeVisible">
        <span class="label">正常範囲：</span>
        <input class="form-control form-control-sm" type="number" :min="min.range" :max="max.range"
          v-model.number="range.low" :disabled="formDisabled"
          :class="invalidRange ? 'is-invalid' : ''" />
        <span class="extra">～</span>
        <input class="form-control form-control-sm" type="number" :min="min.range" :max="max.range"
          v-model.number="range.high" :disabled="formDisabled"
          :class="invalidRange ? 'is-invalid' : ''" />
        <span class="extra">{{ unit }}</span>
      </div>
      <div class="content time">
        <span class="label">判定時間：</span>
        <input class="form-control form-control-sm" type="number" :min="min.timeMin" :max="max.timeMin"
          v-if="minVisible"
          v-model.number="time.min"
          :disabled="formDisabled"
          :class="invalidTime ? 'is-invalid' : ''"
        />
        <span v-if="minVisible" class="extra minute">分</span>
        <input class="form-control form-control-sm" type="number" :min="min.timeSec" :max="max.timeSec"
          v-if="secVisible"
          v-model.number="time.sec"
          :disabled="formDisabled"
          :class="invalidTime ? 'is-invalid' : ''"
        />
        <span v-if="secVisible" class="extra second">秒</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Icon from '@/view/common/Icon';
import SwitchButton from '@/view/common/SwitchButton';
import Headline from '@/view/common/Headline';
import BedSensorSettingEntity   from '@/model/entity/sensor-setting/BedSensorSettingEntity';

const TYPE = ['breath', 'heart', 'bathe'];

const ALERT_KEY_BOUTIN_CTIM = 'boutinCtim';
const ALERT_KEY_BR_RANGE = 'brRange';
const ALERT_KEY_AP_RANGE = 'apRange';
const ALERT_KEY_MIN = 'min';
const ALERT_KEY_MAX = 'max';
const ALERT_KEY_SEC = 'sec';
const ALERT_KEY_SEC_FLG = 'secFlg';
const ALERT_KEY_IN_MIN = 'inMin';
const ALERT_KEY_IN_MIN_FLG = 'inMinFlg';

const RANGE_MIN = [];
RANGE_MIN[Constants.ALERT_MAT_BREATH]      = 0;
RANGE_MIN[Constants.ALERT_MAT_HEART]       = 0;
const RANGE_MAX = [];
RANGE_MAX[Constants.ALERT_MAT_BREATH]      = 99;
RANGE_MAX[Constants.ALERT_MAT_HEART]       = 999;
const TIME_MINUTE_MIN = [];
TIME_MINUTE_MIN[Constants.ALERT_MAT_BREATH]      = 0;
TIME_MINUTE_MIN[Constants.ALERT_MAT_HEART]       = 0;
TIME_MINUTE_MIN[Constants.ALERT_MAT_LONG_BATHE]       = 1;
const TIME_MINUTE_MAX = [];
TIME_MINUTE_MAX[Constants.ALERT_MAT_BREATH]      = 15;
TIME_MINUTE_MAX[Constants.ALERT_MAT_HEART]       = 15;
TIME_MINUTE_MAX[Constants.ALERT_MAT_LONG_BATHE]       = 9998;

export default {
  name: 'AlertSetting',
  components: {
    Icon,
    Headline,
    SwitchButton
  },
  props: {
    type: { type: String, validator: (val) => TYPE.includes(val) },
    setting: { type: BedSensorSettingEntity },
    foundSensor: { type: Boolean, default: true },
    isChange: { type: Boolean, require: true },
  },
  data () {
    return {
      rangeVisible: true,
      minVisible: true,
      secVisible: true,
      invalidTime: false,
      invalidRange: false,
      min: {
        range: 0,
        timeMin: 0,
        timeSec: 0
      },
      max: {
        range: 99,
        timeMin: 16,
        timeSec: 59
      },
      onoff: false,
      range: {
        low: '',
        high: '',
      },
      time: {
        min: '',
        sec: ''
      }
    };
  },
  computed: {
    typeCode () {
      return Constants.ALERT_TYPE[this.type];
    },
    titleText () {
      return Constants.ALERT_NAME[this.typeCode];
    },
    id () {
      return 'alert' + this.typeCode;
    },
    tooltip () {
      return Constants.ALERT_EXPLORER[this.typeCode] || '';
    },
    unit () {
      switch (this.typeCode) {
        case Constants.ALERT_MAT_BREATH:
        case Constants.ALERT_MAT_HEART:
          return '回/分';
      }
      return '';
    },
    formDisabled () {
      if (this.foundSensor && this.haveSensorSetting) {
        // センサがあるならON/OFFによって切り替え
        return !this.onoff;
      }
      // センサがない状態なら全部無効
      return true;
    },
    haveSensorSetting () {
      return !_.isUndefined(this.setting);
    },
    validate () {
      if (this.haveSensorSetting) {
        let result = true;
        Vue.set(this, 'invalidRange', false);
        Vue.set(this, 'invalidTime', false);
        switch (this.typeCode) {
          case Constants.ALERT_MAT_LONG_BATHE:
            if (this.onoff) {
              // 有効
              // 必須チェック
              if (this.time.min === '') {
                Vue.set(this, 'invalidTime', true);
                result = false;
              }
              // 範囲チェック
              else if (!(this.time.min >= this.min.timeMin && this.time.min <= this.max.timeMin)) {
                // 判定時間(分)：下限以上、上限以下ではない場合
                Vue.set(this, 'invalidTime', true);
                result = false;
              }
            }
            break;
          case Constants.ALERT_MAT_BREATH:
          case Constants.ALERT_MAT_HEART:
            if (this.onoff) {
              // 有効
              if (this.time.min === '' || this.time.sec === '') {
                Vue.set(this, 'invalidTime', true);
                result = false;
              }
              else {
                if (!(this.time.min >= this.min.timeMin && this.time.min <= this.max.timeMin)) {
                  // 判定時間(分)：下限以上、上限以下ではない場合
                  Vue.set(this, 'invalidTime', true);
                  result = false;
                }
                if (!(this.time.sec >= this.min.timeSec && this.time.sec <= this.max.timeSec)) {
                  // 判定時間(秒)：下限以上、上限以下ではない場合
                  Vue.set(this, 'invalidTime', true);
                  result = false;
                }
              }
              if (this.range.low === '' || this.range.high === '') {
                Vue.set(this, 'invalidRange', true);
                result = false;
              }
              else {
                if (!(this.range.low >= this.min.range && this.range.low < this.range.high)) {
                  // 正常範囲（下限）：下限以上、high未満ではない場合
                  Vue.set(this, 'invalidRange', true);
                  result = false;
                }
                if (!(this.range.high > this.range.low && this.range.high <= this.max.range)) {
                  // 正常範囲（下限）：上限以下、lowより大きい値ではない場合
                  Vue.set(this, 'invalidRange', true);
                  result = false;
                }
              }
            }
            break;
        }
        return result;
      }
      return false;
    },
    diff () {
      let diff = {};
      if (this.haveSensorSetting) {
        let old = BedSensorSettingEntity.clone(this.setting);
        switch (this.typeCode) {
          case Constants.ALERT_MAT_LONG_BATHE:
            // 入浴時間
            let boutinCtimFlg = false;
            let boutinCtim = old.boutinCtim;
            if (!_.isEqual(this.onoff, old.boutinCtim.inMinFlg)) {
              boutinCtimFlg = true;
            }
            if (this.onoff) {
              // ONの場合
              if (!_.isEqual(this.time.min, old.boutinCtim.inMin)) {
                boutinCtimFlg = true;
                boutinCtim[ALERT_KEY_IN_MIN] = this.time.min;
              }
              else {
                // 秒は変化なしなら前の値を含める
                boutinCtim[ALERT_KEY_IN_MIN] = old.boutinCtim.inMin;
              }
            }
            // 変更あればdiffに含める
            if (boutinCtimFlg) {
              // 変更があるなら必ずフラグは設定する
              boutinCtim[ALERT_KEY_IN_MIN_FLG] = this.onoff;
              diff[ALERT_KEY_BOUTIN_CTIM] = boutinCtim;
            }
            break;
          case Constants.ALERT_MAT_BREATH:
            // 呼吸
            let brRangeFlg = false;
            let brRange = old.brRange;
            if (!_.isEqual(this.onoff, old.brRange.secFlg)) {
              brRangeFlg = true;
            }

            if (this.onoff) {
              // ONの場合、変更したものを含める
              if (!_.isEqual(this.range.low, old.brRange.min)) {
                brRangeFlg = true;
                brRange[ALERT_KEY_MIN] = this.range.low;
              }
              if (!_.isEqual(this.range.high, old.brRange.max)) {
                brRangeFlg = true;
                brRange[ALERT_KEY_MAX] = this.range.high;
              }
              let sec = (this.time.min * 60) + this.time.sec;
              if (!_.isEqual(sec, old.brRange.sec)) {
                brRangeFlg = true;
                brRange[ALERT_KEY_SEC] = sec;
              }
              else {
                // 秒は変化なしなら前の値を含める
                brRange[ALERT_KEY_SEC] = old.brRange.sec;
              }
            }
            // 変更あればdiffに含める
            if (brRangeFlg) {
              // 変更があるなら必ずフラグは設定する
              brRange[ALERT_KEY_SEC_FLG] = this.onoff;
              diff[ALERT_KEY_BR_RANGE] = brRange;
            }
            break;
          case Constants.ALERT_MAT_HEART:
            // 脈拍
            let apRangeFlg = false;
            let apRange = old.apRange;
            if (!_.isEqual(this.onoff, old.apRange.secFlg)) {
              apRangeFlg = true;
              apRange[ALERT_KEY_SEC_FLG] = this.onoff;
            }
            if (this.onoff) {
              // ONの場合
              if (!_.isEqual(this.range.low, old.apRange.min)) {
                apRangeFlg = true;
                apRange[ALERT_KEY_MIN] = this.range.low;
              }
              if (!_.isEqual(this.range.high, old.apRange.max)) {
                apRangeFlg = true;
                apRange[ALERT_KEY_MAX] = this.range.high;
              }
              let sec = (this.time.min * 60) + this.time.sec;
              if (!_.isEqual(sec, old.apRange.sec)) {
                apRangeFlg = true;
                apRange[ALERT_KEY_SEC] = sec;
                apRange[ALERT_KEY_SEC_FLG] = this.onoff;
              }
            }
            // 変更あればdiffに含める
            if (apRangeFlg) {
              diff[ALERT_KEY_AP_RANGE] = apRange;
            }
            break;
        }
      }
      return diff;
    },
    changed () {
      if (this.haveSensorSetting) {
        if (!_.isEmpty(this.diff)) {
          // アラート設定が変更していたらtrue
          return this.validate;
        }
        Vue.set(this, 'invalidRange', false);
        Vue.set(this, 'invalidTime', false);
        return false;
      }
      else {
        // アラート設定がないなら変わりようがないのでfalse
        Vue.set(this, 'invalidRange', false);
        Vue.set(this, 'invalidTime', false);
        return false;
      }
    }
  },
  watch: {
    'time.min' : function () {
      if (this.time.sec === '') {
        this.time.sec = 0;
      }
      switch (this.typeCode) {
        case Constants.ALERT_MAT_BREATH:
        case Constants.ALERT_MAT_HEART:
          this.min.timeSec = this.time.min === 0 ? 30 : 0;
      }
    },
    'time.sec' : function () {
      if (this.time.min === '') {
        this.time.min = 0;
      }
    },
    diff () {
      this.$emit('update:isChange', this.changed);
    },
  },
  methods: {
    init () {
      let typeCode = Constants.ALERT_TYPE[this.type];
      switch (typeCode) {
        // 可視設定
        case Constants.ALERT_MAT_LONG_BATHE:
          this.rangeVisible = false;
          this.secVisible = false;
      }
      if (!_.isUndefined(this.setting)) {
        let data = BedSensorSettingEntity.clone(this.setting);
        switch (typeCode) {
          case Constants.ALERT_MAT_LONG_BATHE:
            // 連続入浴時間
            if (!_.isUndefined(data.boutinCtim.inMin)) {
              this.time.min = data.boutinCtim.inMin;
            }
            this.onoff = data.boutinCtim.inMinFlg;
            break;
          case Constants.ALERT_MAT_BREATH:
            this.min.range = RANGE_MIN[typeCode];
            this.max.range = RANGE_MAX[typeCode];
            // 正常呼吸範囲
            this.range.low = data.brRange.min;
            this.range.high = data.brRange.max;
            // 呼吸異常連続時間
            if (!_.isUndefined(data.brRange.sec) && !_.isEmpty(String(data.brRange.sec))) {
              if (data.brRange.sec === 0) {
                this.time.sec = data.brRange.sec;
              }
              else {
                this.time.min = Math.floor(data.brRange.sec / 60);
                this.time.sec = data.brRange.sec % 60;
              }
            }
            this.onoff = data.brRange.secFlg;
            break;
          case Constants.ALERT_MAT_HEART:
            this.min.range = RANGE_MIN[typeCode];
            this.max.range = RANGE_MAX[typeCode];
            // 正常脈拍範囲
            this.range.low = data.apRange.min;
            this.range.high = data.apRange.max;
            // 脈拍異常連続時間
            if (!_.isUndefined(data.apRange.sec) && !_.isEmpty(String(data.apRange.sec))) {
              if (data.apRange.sec === 0) {
                this.time.sec = data.apRange.sec;
              }
              else {
                this.time.min = Math.floor(data.apRange.sec / 60);
                this.time.sec = data.apRange.sec % 60;
              }
            }
            this.onoff = data.apRange.secFlg;
            break;
        }
      }
      this.min.timeMin = TIME_MINUTE_MIN[typeCode];
      this.max.timeMin = TIME_MINUTE_MAX[typeCode];
    },
    reload () {
      this.init();
    }
  },
  created () {
    this.init();
  }
};
</script>

<style scoped>
.jsp-alert-setting {
  position: relative;
  width: 228px;
  height: 120px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}
.jsp-alert-title-area {
  height: 40px;
  border-radius: 8px 8px 0 0;
  padding: 8px;
  display: flex;
  align-items: center;
}
.jsp-alert-title-area > .jsp-headline .jsp-switch-button {
  position: absolute;
  right: 10px;
  top: 6px;
}
.jsp-alert-setting.breath {
  background-color: #e9f4f2;
}
.jsp-alert-setting.heart {
  background-color: #f8e9e2;
}
.jsp-alert-setting.bathe {
  background-color: #dffae3;
}
.jsp-alert-title-area.breath {
  background-color: #d3d8eb;
  color: #415492;
}
.jsp-alert-title-area.heart {
  background-color: #ffc8c8;
  color: #d75251;
}
.jsp-alert-title-area.bathe {
  background-color: #b9efc5;
  color: #4ab162;
}
.content > * {
  display: inline-block;
  margin-bottom: 2px;
}
.content > input {
  width: 53px;
}
.content .form-control-sm {
  height: calc(1.5125rem);
  font-size: 0.75rem;
}
.jsp-alert-setting.bathe .content > input {
  width: 80px !important;
}
.config-area {
  padding: 8px 2px 8px 4px;
  font-size: 12px;
}
.extra {
  margin-left: 4px;
  margin-right: 4px;
  white-space: nowrap;
  overflow: visible;
}
</style>
