const PREFECTURES = ['北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県', '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県', '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県', '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'];

const EMPTY_VALUE = -1;
const EMPTY_TEMPERATURE = -99;

const STATUS_NOT_FOUND_BED    = -6;
const STATUS_NO_GATEWAY       = -3;
const STATUS_NO_SENSOR        = -2;
const STATUS_NO_DATA          = -1;
const STATUS_LEAVE            = 0;
const STATUS_LYING            = 1;
const STATUS_BREATH_WARN      = 2;
const STATUS_HEART_WARN       = 3;
const STATUS_LONG_BATHE_WARN  = 4;
const STATUS_LIGHT_OFF        = 8;
const STATUS_TEXT = {};
// 改行しても良いのは、入浴時間を表示しない場合のみ、それ以外で改行すると枠からはみ出る
STATUS_TEXT[STATUS_NOT_FOUND_BED]    = 'お風呂センサーが\r\n見つかりません';
STATUS_TEXT[STATUS_NO_GATEWAY]       = '見守りゲートウェイが\r\n未設定です';
STATUS_TEXT[STATUS_NO_SENSOR]        = 'お風呂センサーが\r\n未設定です';
STATUS_TEXT[STATUS_NO_DATA]          = 'センサーデータが\r\nありません';
// 12文字まで
STATUS_TEXT[STATUS_LEAVE]            = '入浴していません';
STATUS_TEXT[STATUS_LYING]            = '入浴中です';
STATUS_TEXT[STATUS_BREATH_WARN]      = '呼吸注意';
STATUS_TEXT[STATUS_HEART_WARN]       = '脈拍注意';
STATUS_TEXT[STATUS_LONG_BATHE_WARN]  = '入浴時間注意';
STATUS_TEXT[STATUS_LIGHT_OFF]        = '照明OFF';
const STATUS_ICON = {};
STATUS_ICON[STATUS_NOT_FOUND_BED]    = 'no-data';
STATUS_ICON[STATUS_NO_GATEWAY]       = 'no-data';
STATUS_ICON[STATUS_NO_SENSOR]        = 'no-data';
STATUS_ICON[STATUS_NO_DATA]          = 'no-data';
STATUS_ICON[STATUS_LEAVE]            = 'leave';
STATUS_ICON[STATUS_LYING]            = 'lying';
STATUS_ICON[STATUS_BREATH_WARN]      = 'lying';
STATUS_ICON[STATUS_HEART_WARN]       = 'lying';
STATUS_ICON[STATUS_LONG_BATHE_WARN]  = 'lying';
STATUS_ICON[STATUS_LIGHT_OFF]        = 'illumination-off';
const STATUS_COLOR = {};
STATUS_COLOR[STATUS_NOT_FOUND_BED]    = 'status-no-data';
STATUS_COLOR[STATUS_NO_GATEWAY]       = 'status-no-data';
STATUS_COLOR[STATUS_NO_SENSOR]        = 'status-no-data';
STATUS_COLOR[STATUS_NO_DATA]          = 'status-no-data';
STATUS_COLOR[STATUS_LEAVE]            = 'status-leave';
STATUS_COLOR[STATUS_LYING]            = 'status-lying';
STATUS_COLOR[STATUS_LIGHT_OFF]        = 'status-leave'; // 離床と一緒の色
const STATUS_COLOR_ALERT = 'status-alerting';
const STATUS_COLOR_WARNING = 'status-warning';
STATUS_COLOR[STATUS_BREATH_WARN]      = STATUS_COLOR_WARNING;
STATUS_COLOR[STATUS_HEART_WARN]       = STATUS_COLOR_WARNING;
STATUS_COLOR[STATUS_LONG_BATHE_WARN]  = STATUS_COLOR_WARNING;

const HISTORY_NO_DATA = -1;
const HISTORY_LEAVE   = STATUS_LEAVE;
const HISTORY_LYING   = STATUS_LYING;
const HISTORY_ALERT   = 999;
const HISTORY_COLOR = {};
HISTORY_COLOR[HISTORY_NO_DATA] = 'history-no-data';
HISTORY_COLOR[HISTORY_LEAVE]   = 'history-leave';
HISTORY_COLOR[HISTORY_LYING]   = 'history-lying';
HISTORY_COLOR[HISTORY_ALERT]   = 'history-alert';

const SENSOR_BED    = '1000';
const SENSOR_LIVING = '1001';
const SENSOR_TOILET = '1002';
const SENSOR_NAME = {};
SENSOR_NAME[SENSOR_BED]    = 'ベッドセンサー';
SENSOR_NAME[SENSOR_LIVING] = 'リビングセンサー';
SENSOR_NAME[SENSOR_TOILET] = 'トイレセンサー';

const ALERT_MAT_BREATH       = 2150; // 呼吸異常
const ALERT_MAT_HEART        = 2151; // 脈拍異常
const ALERT_MAT_LONG_BATHE   = 2155; // 入浴時間超過

const ALERT_TYPE_BATHE = 'bathe';
const ALERT_TYPE_BREATH = 'breath';
const ALERT_TYPE_HEART = 'heart';
const ALERT_TYPE = {};
ALERT_TYPE[ALERT_TYPE_BATHE] = ALERT_MAT_LONG_BATHE;
ALERT_TYPE[ALERT_TYPE_BREATH] = ALERT_MAT_BREATH;
ALERT_TYPE[ALERT_TYPE_HEART] = ALERT_MAT_HEART;

// アラートが出たときのステータステキスト
STATUS_TEXT[ALERT_MAT_BREATH]       = '呼吸に異常があります';
STATUS_TEXT[ALERT_MAT_HEART]        = '脈拍に異常があります';
STATUS_TEXT[ALERT_MAT_LONG_BATHE]   = '長時間入浴しています';

const ALERT_TEXT = {};
ALERT_TEXT[ALERT_MAT_BREATH]       = '呼吸異常';
ALERT_TEXT[ALERT_MAT_HEART]        = '脈拍異常';
ALERT_TEXT[ALERT_MAT_LONG_BATHE]   = '入浴時間超過';
const ALERT_NAME = {};
ALERT_NAME[ALERT_MAT_BREATH]      = '呼吸';
ALERT_NAME[ALERT_MAT_HEART]       = '脈拍';
ALERT_NAME[ALERT_MAT_LONG_BATHE]   = '入浴時間';
const ALERT_EXPLORER = {};
ALERT_EXPLORER[ALERT_MAT_BREATH]      = '呼吸に異常があることをお知らせします。';
ALERT_EXPLORER[ALERT_MAT_HEART]       = '脈拍に異常があることをお知らせします。';
ALERT_EXPLORER[ALERT_MAT_LONG_BATHE]   = '長時間入浴していることをお知らせします。';
const ALERT_COLOR = {};
ALERT_COLOR[ALERT_MAT_BREATH]       = 'breath';
ALERT_COLOR[ALERT_MAT_HEART]        = 'heart';
ALERT_COLOR[ALERT_MAT_LONG_BATHE]       = 'long-bathe';
const ALERT_SETTING_ICON = {};
ALERT_SETTING_ICON[ALERT_MAT_BREATH]      = 'breath';
ALERT_SETTING_ICON[ALERT_MAT_HEART]       = 'heart';
ALERT_SETTING_ICON[ALERT_MAT_LONG_BATHE]  = 'bathe';
const ALERT_SETTING_COLOR = {};
ALERT_SETTING_COLOR[ALERT_MAT_BREATH]      = 'color-breath';
ALERT_SETTING_COLOR[ALERT_MAT_HEART]       = 'color-heart';
ALERT_SETTING_COLOR[ALERT_MAT_LONG_BATHE]  = 'color-bathe';

const LIST_GROUP_MODE_NONE  = 0;
const LIST_GROUP_MODE_STAFF = 1;

const FLOOR_NONE = 'フロア未設定';

const REALTIME_PLOT_ONCE = 10;
const REALTIME_PLOT_SECOND = 10;
const REALTIME_PLOT_MAX = REALTIME_PLOT_ONCE * REALTIME_PLOT_SECOND;

const DETAIL_ILLUMINANCE_ADJUST = 10;

const WSKEY_AUTH_TOKEN = 'authToken';
const WSKEY_AUTO_LOGIN = 'autoLogin';
const WSKEY_AUTO_LOGIN_MAIL = 'autoLoginMail';
const WSKEY_AUTO_LOGIN_PASS = 'autoLoginPass';

const MASTER_STAFF_ID = 'master';

const FACILITY_MODE_STAFF = 'staff';
const FACILITY_MODE_FLOOR = 'floor';

export default {
  PREFECTURES,

  EMPTY_VALUE,
  EMPTY_TEMPERATURE,

  STATUS_NOT_FOUND_BED,

  STATUS_NO_GATEWAY,
  STATUS_NO_SENSOR,
  STATUS_NO_DATA,
  STATUS_LEAVE,
  STATUS_LYING,
  STATUS_BREATH_WARN,
  STATUS_HEART_WARN,
  STATUS_LONG_BATHE_WARN,

  STATUS_TEXT,
  STATUS_ICON,
  STATUS_COLOR,
  STATUS_COLOR_ALERT,

  HISTORY_NO_DATA,
  HISTORY_LEAVE,
  HISTORY_LYING,
  HISTORY_ALERT,
  HISTORY_COLOR,

  SENSOR_BED,
  SENSOR_LIVING,
  SENSOR_TOILET,
  SENSOR_NAME,

  ALERT_MAT_BREATH,
  ALERT_MAT_HEART,

  ALERT_MAT_LONG_BATHE,
  ALERT_TEXT,
  ALERT_NAME,
  ALERT_EXPLORER,
  ALERT_COLOR,
  ALERT_SETTING_ICON,
  ALERT_SETTING_COLOR,

  ALERT_TYPE,

  LIST_GROUP_MODE_NONE,
  LIST_GROUP_MODE_STAFF,

  FLOOR_NONE,

  REALTIME_PLOT_MAX,

  DETAIL_ILLUMINANCE_ADJUST,

  WSKEY_AUTH_TOKEN,
  WSKEY_AUTO_LOGIN,
  WSKEY_AUTO_LOGIN_MAIL,
  WSKEY_AUTO_LOGIN_PASS,

  MASTER_STAFF_ID,

  FACILITY_MODE_STAFF,
  FACILITY_MODE_FLOOR,
};
