import Translator from '@/model/websocket/Translator';

const COMPRESSION = Utils.getWebSocketGzip();

// WebSocket接続タイプ: CL⇒SV
const CONNECTION_TYPE = 1;

// 認証トークン
let authToken;

// WebSocketインスタンス
let ws;

// WebSocket切断時の再接続タイマー
let reconnectTimeoutId = false;

// 要求／応答を管理するオブジェクト
let request = {};
if (Utils.isDebug()) {
  window.request = request;
}
function setRequest (cl, key, value) {
  // if (_.isUndefined(request[cl])) request[cl] = {};
  // request[cl][key] = value;

  // CL電文1種につき、1リクエストまで保持する。
  // 多重にリクエストが飛んだ場合、最後の1件を保持する。
  request[cl] = {};
  request[cl][key] = value;
}
function getRequest (cl, key) {
  if (_.isUndefined(request[cl])) return undefined;
  let req = request[cl][key];
  delete request[cl][key];
  return req;
}

// 通信を特定するシーケンス番号
let seq = _.random(1000000000);

function getUrl () {
  let protocol = Utils.getWebSocketProtocol();
  let host = Utils.getWebSocketHost();
  let port = Utils.getWebSocketPort();
  let path = Utils.getWebSocketPath();
  let params = {
    authToken,
    type: CONNECTION_TYPE,
  };
  params = _.map(params, (value, key) => `${key}=${value}`).join('&');
  return `${protocol}//${host}:${port}${path}?${params}`;
}

function send (msg) {
  Log.debug(msg);
  if (_.isUndefined(ws)) {
    Log.warn('WebSocket is not connected.');
    return;
  }
  if (COMPRESSION) {
    Utils.gzip(msg, (gzip) => {
      ws.send(gzip);
    });
  }
  else {
    ws.send(msg);
  }
}

function onOpen () {
  Log.log();
  request = {};
  Translator.connected();

  if (Utils.isDebug()) {
    window.ws = ws;
  }
}

function onError (e) {
  // Log.error(e);
}

function onClose (e) {
  Log.error(e);

  ws = undefined;

  // 認証エラーかその他のエラーによって処理を分ける
  if (e.code === 4001) {
    // 認証エラーの場合はログイン画面へ遷移
    Translator.disconnectedByAuthError();
  }
  else {
    // その他のエラーの場合は5秒後に再接続を試みる
    Translator.disconnected();
    reconnectTimeoutId = setTimeout(WebSocket.connect, 5000, authToken);
  }
}

function onMessage (msg) {
  if (!ws) {
    Log.warn('ws is not exist. skip.');
    return;
  }
  try {
    if (COMPRESSION) {
      Utils.gunzip(msg.data, (str) => {
        procMessage(JSON.parse(str));
      });
    }
    else {
      procMessage(JSON.parse(msg.data));
    }
  }
  catch (e) {
    Log.error(msg, e);
  }
}

function procMessage (json) {
  Log.debug(json);

  // リクエストを取得
  let req;
  if (json.head.type === 2) {
    // 自分の要求に対する応答でない場合は無視する
    req = getRequest(json.head.id, json.head.seq);
    if (_.isUndefined(req)) {
      Log.warn('This response is not addressed to me or has timed out.', json);
      return;
    }
    Log.debug('request:', req);
  }

  // エラー応答フック
  if (json.result !== 'OK' && json.head.type === 2) {
    switch (json.head.id) {
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
      case 17:
      case 19:
      case 20:
      case 21:
        Translator.hookError(json, req);
        break;
    }
  }

  // CL3 通知
  if (json.head.id === 3 && json.head.type === 0) {
    Translator.translateCL3Notify(json);
  }

  // CL4 通知
  else if (json.head.id === 4 && json.head.type === 0) {
    Translator.translateCL4Notify(json);
  }

  // CL4 応答
  else if (json.head.id === 4 && json.head.type === 2) {
    Translator.translateCL4Response(json);
  }

  // CL5 通知
  else if (json.head.id === 5 && json.head.type === 0) {
    Translator.translateCL5Notify(json);
  }

  // CL5 応答
  else if (json.head.id === 5 && json.head.type === 2) {
    Translator.translateCL5Response(json);
  }

  // CL6 応答
  else if (json.head.id === 6 && json.head.type === 2) {
    Translator.translateCL6Response(json, req);
  }

  // CL7 応答
  else if (json.head.id === 7 && json.head.type === 2) {
    Translator.translateCL7Response(json, req.callback);
  }

  // CL8 応答
  else if (json.head.id === 8 && json.head.type === 2) {
    Translator.translateCL8Response(json, req.callback);
  }

  // CL9 応答
  else if (json.head.id === 9 && json.head.type === 2) {
    Translator.translateCL9Response(json, req.callback);
  }

  // CL13 応答
  else if (json.head.id === 13 && json.head.type === 2) {
    Translator.translateCL13Response(json);
  }

  // CL17 応答
  else if (json.head.id === 17 && json.head.type === 2) {
    Translator.translateCL17Response(json, req.callback);
  }

  // CL19 応答
  else if (json.head.id === 19 && json.head.type === 2) {
    Translator.translateCL19Response(json, req.callback);
  }

  // CL20 応答
  else if (json.head.id === 20 && json.head.type === 2) {
    Translator.translateCL20Response(json, req.callback);
  }

  // CL21 応答
  else if (json.head.id === 21 && json.head.type === 2) {
    Translator.translateCL21Response(json, req.callback);
  }
}

function getSeq () {
  return 'br' + seq++;
}

export default class WebSocket {

  /**
   * WebSocket接続を開始する関数
   * @param  {String} authToken_ 認証トークン
   */
  static connect (authToken_) {
    if (ws) {
      Log.warn('ws has already existed. skip.');
      return;
    }
    authToken = authToken_;

    // WebSocket接続先URL生成
    let url = getUrl();

    // WebSocket初期化
    Log.log(url);
    ws = new window.WebSocket(url);
    ws.onopen = onOpen;
    ws.onerror = onError;
    ws.onclose = onClose;
    ws.onmessage = onMessage;
  }

  /**
   * WebSocket接続を切断する関数
   */
  static disconnect () {
    Log.log('ws is ' + (ws ? 'exist' : 'not exist'));
    if (!_.isUndefined(ws)) {
      ws.onclose = undefined;
      ws.close();
      ws = undefined;
    }
    clearTimeout(reconnectTimeoutId);

    // ログインエラーと同じエラーコードで切断されたことにする
    onClose({ code: 4001 });
  }

  /**
   * ログアウト要求
   */
  static requestCL2 (callback) {
    let msg = {
      head: { id: 2, type: 1, ver: 0, seq: getSeq() },
    };
    send(JSON.stringify(msg));
    setRequest(msg.head.id, msg.head.seq, callback ? { callback } : true);
  }

  /**
   * 詳細データ取得要求
   * @param  {String} userId ユーザーID
   * @param  {Number} datetime   要求する時刻
   */
  static requestCL4 (userId, datetime, period) {
    let msg = {
      head: { id: 4, type: 1, ver: 0, seq: getSeq() },
      userId,
      datetime,
      period
    };
    send(JSON.stringify(msg));
    setRequest(msg.head.id, msg.head.seq, true);
  }

  /**
   * 過去データ取得要求
   * @param  {String} userId ユーザーID
   */
  static requestCL5 (userId, date) {
    let msg = {
      head: { id: 5, type: 1, ver: 0, seq: getSeq() },
      userId,
      date,
    };
    send(JSON.stringify(msg));
    setRequest(msg.head.id, msg.head.seq, true);
  }

  /**
   * 詳細データ通知制御要求
   * @param  {String}  userId  ユーザーID
   * @param  {String}  control 制御命令
   * @param  {Boolean} initial 初回制御フラグ
   */
  static requestCL6 (userId, control, initial) {
    let msg = {
      head: { id: 6, type: 1, ver: 0, seq: getSeq() },
      userId,
      control,
    };
    send(JSON.stringify(msg));
    setRequest(msg.head.id, msg.head.seq, initial ? { initial } : true);
  }

  /**
   * ユーザー追加・削除・設定変更要求
   * @param  {Object}   payload  各種引数を格納したペイロード
   * @param  {Function} callback レスポンスを処理するコールバック関数
   */
  static requestCL7 (payload, callback) {
    let msg = payload;
    msg.head = { id: 7, type: 1, ver: 0, seq: getSeq() };
    send(JSON.stringify(msg));
    setRequest(msg.head.id, msg.head.seq, callback ? { callback } : true);
  }

  /**
   * スタッフ追加・削除・設定変更要求
   * @param  {Object}   payload  各種引数を格納したペイロード
   * @param  {Function} callback レスポンスを処理するコールバック関数
   */
  static requestCL8 (payload, callback) {
    let msg = payload;
    msg.head = { id: 8, type: 1, ver: 0, seq: getSeq() };
    send(JSON.stringify(msg));
    setRequest(msg.head.id, msg.head.seq, callback ? { callback } : true);
  }

  /**
   * スタッフパスワード初期化要求
   * @param  {String} staffId スタッフID
   */
  static requestCL9 (staffId, callback) {
    let msg = {
      head: { id: 9, type: 1, ver: 0, seq: getSeq() },
      staffId,
    };
    send(JSON.stringify(msg));
    setRequest(msg.head.id, msg.head.seq, callback ? { callback } : true);
  }

  /**
   * セッション有効期限延長要求
   */
  static requestCL11 () {
    let msg = {
      head: { id: 11, type: 1, ver: 0, seq: getSeq() },
    };
    send(JSON.stringify(msg));
    setRequest(msg.head.id, msg.head.seq, true);
  }

  /**
   * アラート確認状態変更要求
   * @param  {Object} payload 各種引数を格納したペイロード
   */
  static requestCL13 (userId) {
    let msg = {
      head: { id: 13, type: 1, ver: 0, seq: getSeq() },
      userId,
    };
    send(JSON.stringify(msg));
    setRequest(msg.head.id, msg.head.seq, true);
  }

  /**
   * センサ設定変更要求
   * @param  {Object}   payload  各種引数を格納したペイロード
   * @param  {Function} callback レスポンスを処理するコールバック関数
   */
  static requestCL17 (payload, callback) {
    let msg = payload;
    msg.head = { id: 17, type: 1, ver: 0, seq: getSeq() };
    send(JSON.stringify(msg));
    setRequest(msg.head.id, msg.head.seq, callback ? { callback } : true);
  }

  /**
   * センサリセット要求
   * @param  {Object}   payload  各種引数を格納したペイロード
   * @param  {Function} callback レスポンスを処理するコールバック関数
   */
  static requestCL19 (payload, callback) {
    let msg = payload;
    msg.head = { id: 19, type: 1, ver: 0, seq: getSeq() };
    send(JSON.stringify(msg));
    setRequest(msg.head.id, msg.head.seq, callback ? { callback } : true);
  }

  /**
   * 入浴判定取得要求
   * @param  {Object}   payload  各種引数を格納したペイロード
   * @param  {Function} callback レスポンスを処理するコールバック関数
   */
  static requestCL20 (payload, callback) {
    let msg = payload;
    msg.head = { id: 20, type: 1, ver: 0, seq: getSeq() };
    send(JSON.stringify(msg));
    setRequest(msg.head.id, msg.head.seq, callback ? { callback } : true);
  }

  /**
   * 入浴判定変更要求
   * @param  {Object}   payload  各種引数を格納したペイロード
   * @param  {Function} callback レスポンスを処理するコールバック関数
   */
  static requestCL21 (payload, callback) {
    let msg = payload;
    msg.head = { id: 21, type: 1, ver: 0, seq: getSeq() };
    send(JSON.stringify(msg));
    setRequest(msg.head.id, msg.head.seq, callback ? { callback } : true);
  }
}
