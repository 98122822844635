export default class BathSettingEntity {
  constructor () {
    this.bathIn = {
      detectSec: '',
      apLow: '',
      apHigh: '',
      mvThreshold: ''
    };
    this.bathOut = {
      block1: {
        intervalSec: '',
        count: '',
        low: '',
        high: ''
      },
      block2: {
        intervalSec: '',
        count: '',
        low: '',
        high: ''
      },
      block3: {
        intervalSec: '',
        count: '',
        low: '',
        high: ''
      }
    };
  }

  static clone (from) {
    let to = new BathSettingEntity();
    if (_.isObject(from.bathIn)) {
      to.bathIn = {
        detectSec: _.isUndefined(from.bathIn.detectSec) ? '' : from.bathIn.detectSec,
        apLow: _.isUndefined(from.bathIn.apLow) ? '' : from.bathIn.apLow,
        apHigh: _.isUndefined(from.bathIn.apHigh) ? '' : from.bathIn.apHigh,
        mvThreshold: _.isUndefined(from.bathIn.mvThreshold) ? '' : from.bathIn.mvThreshold,
      };
    }
    if (_.isObject(from.bathOut)) {
      if (_.isObject(from.bathOut.block1)) {
        to.bathOut.block1 = {
          intervalSec: _.isUndefined(from.bathOut.block1.intervalSec) ? '' : from.bathOut.block1.intervalSec,
          count: _.isUndefined(from.bathOut.block1.count) ? '' : from.bathOut.block1.count,
          low: _.isUndefined(from.bathOut.block1.low) ? '' : from.bathOut.block1.low,
          high: _.isUndefined(from.bathOut.block1.high) ? '' : from.bathOut.block1.high,
        };
      }
      if (_.isObject(from.bathOut.block2)) {
        to.bathOut.block2 = {
          intervalSec: _.isUndefined(from.bathOut.block2.intervalSec) ? '' : from.bathOut.block2.intervalSec,
          count: _.isUndefined(from.bathOut.block2.count) ? '' : from.bathOut.block2.count,
          low: _.isUndefined(from.bathOut.block2.low) ? '' : from.bathOut.block2.low,
          high: _.isUndefined(from.bathOut.block2.high) ? '' : from.bathOut.block2.high,
        };
      }
      if (_.isObject(from.bathOut.block3)) {
        to.bathOut.block3 = {
          intervalSec: _.isUndefined(from.bathOut.block3.intervalSec) ? '' : from.bathOut.block3.intervalSec,
          count: _.isUndefined(from.bathOut.block3.count) ? '' : from.bathOut.block3.count,
          low: _.isUndefined(from.bathOut.block3.low) ? '' : from.bathOut.block3.low,
          high: _.isUndefined(from.bathOut.block3.high) ? '' : from.bathOut.block3.high,
        };
      }
    }
    return to;
  }
}

if (Utils.isDebug()) {
  window.BathSettingEntity = BathSettingEntity;
}
