<template>
  <div class="jsp-setting-item">
    <b-row><b-col cols="12">
      <b-row align-v="center" class="conf-area">
        <b-col cols="6"><b>{{ item.no }}. {{ item.name }}</b></b-col>
        <b-col cols="6">
          <input class="form-control form-control-sm setting" v-if="type === 'number'" :type="type" v-model.number="item.value" :disabled="item.disabled" :required="item.required" :min="item.min" :max="item.max"
          :class="item.invalid ? 'is-invalid' : ''" />
          <input class="form-control form-control-sm setting" v-if="type === 'string'" :type="type" v-model="item.value" :disabled="item.disabled" :required="item.required"
          :class="item.invalid ? 'is-invalid' : ''" :pattern="item.pattern" />
        </b-col>
      </b-row>
      <b-row class="conf-area">
        <b-col class="explain" >{{ item.explain }}</b-col>
      </b-row>
    </b-col></b-row>
  </div>
</template>
<script>
// 番号、設定項目名、設定値、備考
const TYPE = ['number', 'string'];
export default {
  props: {
    type: { type: String, validator: (val) => TYPE.includes(val) },
    item: { type: Object, require: true },
  },
};
</script>

<style scoped>
.explain {
  padding-left: 30px;
  font-size: 12px;
  white-space: pre-wrap;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}
.setting {
  text-align: right;
}
</style>
