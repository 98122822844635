<template>
  <div class="jsp-user-group" >
    <div class="d-flex justify-content-center" v-if="groupName !== ''"><div class="name">{{ groupName }}</div></div>
    <div class="users d-flex justify-content-center">
      <div class="jsp-user-row d-flex justify-content-start flex-wrap">
        <div v-for="user in users" :key="user.id">
          <UserGroupItem :user="user" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserGroupItem   from '@/view/user-list/UserGroupItem';
import UserGroupEntity from '@/model/entity/UserGroupEntity';

export default {
  components: {
    UserGroupItem,
  },
  props: {
    group: { type: UserGroupEntity, require: true },
  },
  data () {
    return {
    };
  },
  computed: {
    groupName () {
      if (_.isUndefined(this.group)) {
        return 'フロアなし';
      }
      return this.group.name;
    },
    users () {
      if (_.isUndefined(this.group)) {
        return [];
      }
      return this.group.users;
    }
  },
  methods: {
  },
};
</script>

<style scoped>
.jsp-user-group {
}
.jsp-user-row {
  /* width: 950px; */
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.name {
  padding: 4px 12px;
  border-radius: 100px;
  background-color: #9aff92;
  cursor: pointer;
  /* width: 950px; */
  width: 99%;
}
.users {
  padding: 0 0 8px 0px;
  overflow: hidden;
  /* min-height: 485px; */
  align-items: flex-start;
}
</style>
