import Vue from 'vue';
import moment from 'moment';
import Translator from '@/model/websocket/Translator';
import DetailEntity from '@/model/entity/DetailEntity';
// import HistoryEntity from '@/model/entity/HistoryEntity';

export default {
  namespaced: true,
  state: {
    detail: undefined,
    months: undefined,
    selectedMonth: undefined,
    histories: undefined,
    selectedHistory: undefined,
    urlDate: undefined,
  },
  getters: {
    detail (state, getters, rootState, rootGetters) {
      return state.detail;
    },
    detail10 (state, getters, rootState, rootGetters) {
      // 年月が空の場合は空応答しておく
      if (_.isArray(state.months) && state.months.length === 0) return new DetailEntity();

      // 空値チェック
      if (_.isUndefined(state.detail)) return undefined;
      if (_.isUndefined(state.selectedHistory)) return undefined;

      let detail = new DetailEntity();
      detail.alerts = state.detail.alerts || [];
      return detail;
    },
    histories (state, getters, rootState, rootGetters) {
      return state.histories;
    },
    selectedHistory (state, getters, rootState, rootGetters) {
      return state.selectedHistory;
    },
    months (state, getters, rootState, rootGetters) {
      return state.months;
    },
    selectedMonth (state, getters, rootState, rootGetters) {
      return state.selectedMonth;
    },
    urlDate (state, getters, rootState, rootGetters) {
      return state.urlDate;
    },
  },
  mutations: {
    setDetail (state, detail) {
      state.detail = detail;
      if (_.isObject(detail) && _.isArray(detail.alerts)) {
        detail.alerts.sort((a, b) => (b.time - a.time));
      }
    },
    addAlert (state, alerts) {
      if (!_.isObject(state.detail)) return;
      let _now = state.detail;
      if (_.isArray(alerts)) {
        let selected = +moment(state.selectedHistory.date).startOf('date');
        _.each(alerts, function (alert) {
          // 選択している日と同じアラートなら追加
          let date = +moment(alert.time).startOf('date');
          if (selected === date) {
            _now.alerts.push(alert);
          }
        });
        _now.alerts.sort((a, b) => (b.time - a.time));
      }
      Vue.set(state, 'detail', state.detail);
    },
    setMonths (state, months) {
      state.months = months;
    },
    updateMonth (state, month) {
      if (_.isArray(state.months)) {
        state.months.push(month);
      }
      else {
        state.months = [month];
      }
    },
    selectMonth (state, month) {
      state.selectedMonth = month;
    },
    setHistories (state, histories) {
      state.histories = histories;
    },
    updateHistories (state, histories) {
      let addFlag = false;
      // 一件ずつ追加または更新する
      _.each(histories, (_new) => {
        // 同じ日時のデータを探す
        let _old = _.find(state.histories, (_old) => _old.date === _new.date);

        // データが見つからなければ追加
        if (_.isUndefined(_old)) {
          state.histories.push(_new);
          addFlag = true;
        }

        // 見つかれば更新
        else {
          if (!_.isUndefined(_new.batheTime)) {
            _old.batheTime = _new.batheTime;
          }
          if (!_.isUndefined(_new.batheFlg)) {
            _old.batheFlg = _new.batheFlg;
          }
          if (!_.isUndefined(_new.alert)) {
            _old.alert = _new.alert;
          }
        }
      });

      // 追加があった場合はソートする
      if (addFlag) {
        sortHistories(state.histories);
      }
    },
    selectHistory (state, history) {
      state.selectedHistory = history;
    },
    setUrlDate (state, date) {
      state.urlDate = date;
    },
  },
  actions: {
    initialize (context) {
      context.dispatch('clear');
    },
    setMonths (context, months) {
      months.sort((a, b) => b - a);
      context.commit('setMonths', months);

      // 年月一覧が空の場合（データが一切ない場合）は histories, detail も空にする
      if (months.length === 0) {
        context.commit('setHistories', []);
        context.commit('setDetail', new DetailEntity());
      }
    },
    selectMonth (context, month) {
      context.commit('selectMonth', month);
      context.dispatch('requestHistories', month);
      context.commit('setHistories', undefined);
    },
    setHistories (context, histories) {
      // 空なら保存のみ
      if (histories.length === 0) {
        context.commit('setHistories', histories);
        return;
      }
      // 降順ソート
      sortHistories(histories);

      // 保存
      context.commit('setHistories', histories);

      // 過去データが未選択の場合はURLで指定されたデータまたは最新データを選択
      if (_.isUndefined(context.getters.selectedHistory)) {
        let history;

        // URL指定があるので、そのデータを探す
        if (!_.isUndefined(context.getters.urlDate)) {
          history = _.find(histories, (h) => h.date === context.getters.urlDate);
        }

        // URLで何も指定されていないか無効なデータを選択されたので
        // 最新データを選択
        if (!_.isObject(history)) {
          history = histories[0];
        }

        // データを選択
        context.commit('selectHistory', history);

        // 有効なデータを選択した場合は、プロットデータを要求
        if (_.isObject(history)) {
          let user = context.rootGetters['User/selectedUser'];
          Translator.requestDetail(user, history.date, history.batheTime);
        }
      }
    },
    updateHistories (context, histories) {
      let month = +moment(context.state.selectedMonth).date(1).startOf('date');
      // 現在選択している年月と一致しなければ破棄
      histories = _.filter(histories, history => {
        return history.month === month;
      });

      // 過去データ一覧があればアップデート
      if (_.isArray(context.state.histories)) {
        context.commit('updateHistories', histories);
      }
    },
    requestHistories (context, date) {
      let user = context.rootGetters['User/selectedUser'];
      Translator.requestHistories(user, date);
    },
    selectHistory (context, history) {
      context.commit('selectHistory', history);

      // 1分プロットを10分に間引く処理が呼ばれないよう
      // 1分データを初期化する。
      // これをやらないと、1度無駄な画面更新が行われる。
      context.commit('setDetail', undefined);

      // 詳細データを要求
      let user = context.rootGetters['User/selectedUser'];
      Translator.requestDetail(user, history.date, history.batheTime);
    },
    setDetail (context, detail) {
      context.commit('setDetail', detail);
    },
    setAlertConfirmed (context) {
      let user = context.rootGetters['User/selectedUser'];
      if (_.isObject(user) === false) return;
      let staff = context.rootGetters['Staff/loginStaff'];
      if (user.staffs.length === 0 ||  user.staffs.indexOf(staff) !== -1) {
        Translator.setAlertConfirmed(user);
      }
    },
    clear (context) {
      context.commit('setMonths', undefined);
      context.commit('selectMonth', undefined);
      context.commit('setHistories', undefined);
      context.commit('selectHistory', undefined);
      context.commit('setDetail', undefined);
    },
    setUrlDate (context, date) {
      context.commit('setUrlDate', date);
    },
    addNotifyAlert (context, alerts) {
      context.commit('addAlert', alerts);
    }
  }
};

function sortHistories (histories) {
  histories.sort(function (a, b) {
    if (a.date > b.date) return -1;
    if (a.date < b.date) return 1;
    return 0;
  });
}
