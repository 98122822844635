<template>
  <Dialog :name="dialogName" class="jsp-alert-setting-update-dialog"
    :width="550"
    height="auto"
    :processing="processing"
  >
    <b-form class="content" @submit.prevent="func">
      <Headline >{{ title }}</Headline>
      <div class='msg-area'>この処理には数分かかる可能性があります。</div>
      <b-row align-h="center" class="buttons">
        <Button color="blue" type="submit" :loading="processing">{{ confirm }}</Button>
        <Button color="blue" :frame="true" @click="hide" :disabled="processing">キャンセル</Button>
      </b-row>
    </b-form>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex';
import Dialog from '@/view/common/Dialog';
import Headline from '@/view/common/Headline';
import Button from '@/view/common/Button';
import SensorSettingEntity from '@/model/entity/sensor-setting/SensorSettingEntity';

const TYPE = ['reset', 'sensor', 'alert'];
export default {
  components: {
    Dialog,
    Headline,
    Button,
  },
  props: {
    type: { type: String, validator: (val) => TYPE.includes(val) },
  },
  data () {
    return {
      payload: undefined,
      processing: false,
    };
  },
  computed : {
    title () {
      switch (this.type) {
        case 'alert':
        case 'sensor':
          return 'コントローラへ送信してもよろしいですか？';
        case 'reset':
          return 'アラート状態をリセットしてもよろしいですか？';
      }
      return '';
    },
    confirm () {
      switch (this.type) {
        case 'alert':
        case 'sensor':
          return '登録';
        case 'reset':
          return 'リセット';
      }
      return '';
    },
    dialogName () {
      switch (this.type) {
        case 'alert':
          return 'alert-setting-update-dialog';
        case 'sensor':
          return 'sensor-setting-update-dialog';
        case 'reset':
          return 'sensor-reset-dialog';
      }
      return '';
    },
    resultMsg () {
      switch (this.type) {
        case 'alert':
          return 'アラート設定を更新しました';
        case 'sensor':
          return 'センサー設定を更新しました';
        case 'reset':
          return 'アラート状態をリセットしました';
      }
      return '';
    },
    func () {
      switch (this.type) {
        case 'alert':
        case 'sensor':
          return this.update;
        case 'reset':
          return this.reset;
      }
      return _.noop();
    }
  },
  watch: {
  },
  methods: {
    show (payload) {
      this.payload = payload;
      this.$modal.show(this.dialogName);
    },
    hide () {
      this.$modal.hide(this.dialogName);
    },
    update () {
      // 処理中フラグ
      this.processing = true;

      if (_.isUndefined(this.payload)) {
        this.processing = false;
        this.hide();
        return;
      }
      // 保存処理
      let sensorSetting = new SensorSettingEntity();
      sensorSetting.code = this.payload.sensorType;
      sensorSetting.setting = this.payload.setting;
      let payload = {
        target: {
          user: this.payload.userId,
          hgw: this.payload.gatewayId,
          sensor: this.payload.sensorId,
        },
        sensorSetting: sensorSetting,
        callback: this.updateResult
      };
      this.updateSensorSetting(payload);
    },
    reset () {
      // 処理中フラグ
      this.processing = true;

      if (_.isUndefined(this.payload)) {
        this.processing = false;
        this.hide();
        return;
      }

      let payload = {
        target: {
          user: this.payload.userId,
          hgw: this.payload.gatewayId,
          sensor: this.payload.sensorId,
        },
        callback: this.updateResult
      };
      this.sensorReset(payload);
    },
    updateResult (isSuccess, errorMessage) {
      Log.log(isSuccess, errorMessage);
      let self = this;
      if (isSuccess) {
        setTimeout(() => {
          self.processing = false;
          this.$notify(self.resultMsg);
          self.hide();
        }, 7000);
      }
      else {
        self.processing = false;
        self.hide();
      }
    },
    ...mapActions('User', [
      'updateSensorSetting', 'sensorReset'
    ])
  },
};
</script>

<style scoped>
.jsp-headline {
  padding-bottom: 3px;
  border-bottom: solid 1px dimgray;
}
.msg-area {
  padding-top: 24px;
  padding-bottom: 24px;
  text-align: center;
}
.buttons > .jsp-button {
  min-width: 100px;
}
.buttons > .jsp-button + .jsp-button {
  margin-left: 8px;
}

.hidden {
  display: none;
}
</style>
