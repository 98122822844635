<template>
  <div class="jsp-history-list-item" :class="{ select }">
    <span class="date">{{ date }} {{ startTime }}</span>
    <svg viewBox="0, 0, 1200, 30" preserveAspectRatio="none">
      <rect width="43" height="30"
        v-for="(plot, i) in bathStatus"
        :x="i * 40"
        :class="color(plot)"
      />
      <g fill="#707070" stroke="#707070" stroke-width="1px">
        <g stroke-dasharray="4,4">
          <!-- 200,200だと見えなくなるので1pxずらしてる -->
          <line v-for="n in 6" :x1="n * 200" y1="0" :x2="(n * 200) - 1" y2="30" />
          <!-- <line :x1="n * 200" y1="0" :x2="n * 200" y2="30" stroke="black" /> -->
        </g>
      </g>
    </svg>
    <span class="endTime">{{ endTime }}</span>
  </div>
</template>

<script>
import moment from 'moment';
import HistoryEntity from '@/model/entity/HistoryEntity';
const MAX_PLOT = 30;
export default {
  props: {
    history: { type: HistoryEntity, require: true },
    select: { type: Boolean, default: false },
  },
  computed: {
    date () {
      return moment(this.history.date).format('MM/DD');
    },
    startTime () {
      return moment(this.history.date).format('HH:mm');
    },
    endTime () {
      return moment(this.history.date).add(this.history.batheTime, 's').format('HH:mm');
    },
    bathStatus () {
      let bathStatus = [];
      let batheTime = Math.ceil(this.history.batheTime / 60);
      batheTime = batheTime > MAX_PLOT ? MAX_PLOT : batheTime;
      let remain = MAX_PLOT - batheTime;
      let colorId = this.isAlert ? Constants.HISTORY_ALERT : Constants.HISTORY_LYING;
      for (let i = 0; i < batheTime; i++) {
        bathStatus.push(colorId);
      }
      for (let i = 0; i < remain; i++) {
        bathStatus.push(Constants.HISTORY_LEAVE);
      }
      return bathStatus;
    },
    isAlert () {
      return this.history.alert;
    }
  },
  methods: {
    color (plot) {
      return Constants.HISTORY_COLOR[plot] || Constants.HISTORY_NO_DATA;
    }
  },
  created () {
  }
};
</script>

<style scoped>
.jsp-history-list-item {
  position: relative;
  display: flex;
  align-items: center;
}
.jsp-history-list-item:before {
  content: '';
  position: absolute;
  left: 105px;
  top: 0;
  right: 45px;
  bottom: 0;
}
.jsp-history-list-item:hover:before {
  border: solid 3px rgba(73, 143, 67, 0.3);
}
.jsp-history-list-item.select:before {
  border: solid 3px #498f43;
}
.date {
  display: inline-block;
  width: 108px;
  text-align: center;
}
.endTime {
  display: inline-block;
  width: 47px;
  text-align: center;
}
svg {
  width: calc(100% - 155px);
  height: 30px;
}
</style>
