<template>
  <b-container class="jsp-manage-user-screen">
    <Headline icon="user">利用者管理</Headline>
    <b-row align-h="center">
      <b-col class="search-area" cols="11">
        <b-row align-v="center" class="text-center">
          <b-col cols="9" class="search-area-input">
            <b-row class="mb-1">
              <b-col class="label" cols="3">
                氏名(漢字/かな)：
              </b-col>
              <b-col cols="3">
                <input
                  class="form-control form-control-sm"
                  v-model="name"
                  placeholder="氏名(漢字/かな)"
                  maxlength="30"
                />
              </b-col>
              <b-col class="label" cols="3">
                フロア：
              </b-col>
              <b-col cols="3">
                <b-form-select v-model="floor" :options="floorOptions" size="sm" />
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="3">
            <Button icon="cross" size="xs" :frame="true" color="red" @click="clearFilter">
              フィルタクリア
            </Button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row align-h="end" class="control-extra">
      <Button icon="plus" size="xs" :shadow="true" color="lightgreen" @click="add">
        新規追加
      </Button>
      <!-- <Button size="xs" :shadow="true" color="lightblue">CSV出力</Button>
      <Button size="xs" :shadow="true" color="lightblue">CSV入力</Button> -->
    </b-row>
    <b-row>
      <b-table bordered small :fields="fields" :items="filteredItems" :sort-compare="sortItems">
        <template slot="floor" slot-scope="data">
          {{ data.item.floor.name }}
        </template>
        <template slot="control" slot-scope="data">
          <Button size="xs" color="blue" icon="edit" @click="edit(data.item)" />
          <Button size="xs" color="red" icon="trash" @click="remove(data.item)" />
          <!-- <Button size="xs" color="orange" icon="alert" @click="editAlert(data.item)" /> -->
          <Button size="xs" color="green" icon="chart" @click="showDetail(data.item)" />
        </template>
      </b-table>
    </b-row>
    <AdminUserSettingDialog ref="userSettingDialog" />
    <DeleteUserDialog ref="deleteUserDialog" />
    <!-- <AdminAlertSettingDialog ref="alertSettingDialog" /> -->
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex';
import Headline from '@/view/common/Headline';
import Button from '@/view/common/Button';
import AdminUserSettingDialog from '@/view/manage-user/AdminUserSettingDialog';
import DeleteUserDialog from '@/view/manage-user/DeleteUserDialog';

const fieldsBase = [
  { key: 'name',         label: '氏名',           sortable: true },
  { key: 'prefecture',   label: '都道府県',       sortable: true },
  { key: 'municipality', label: '住所(市区町村)', sortable: true },
  { key: 'floor',        label: '担当スタッフ',   sortable: true },
  { key: 'control',      label: 'アクション',     class: 'control' },
];

const NO_FILTER = 'フィルタなし';
const NO_FLOOR = { name: '-', nameKana: '' };

export default {
  components: {
    Headline,
    Button,
    AdminUserSettingDialog,
    DeleteUserDialog,
  },
  props: {
  },
  data () {
    return {
      fieldsBase,
      items: [],
      prefecturesBase: [],
      floors: [],
      name: '',
      prefecture: NO_FILTER,
      municipality: '',
      floor: undefined,
    };
  },
  computed: {
    ...mapGetters('User', [
      'users',
    ]),
    floorOptions () {
      let floors = [
        { value: undefined, text: NO_FILTER },
        { value: NO_FLOOR, text: NO_FLOOR.name },
      ];
      _.each(this.floors, (floor) => {
        floors.push({ value: floor, text: floor.name });
      });
      return floors;
    },
    prefectures () {
      return [NO_FILTER].concat(this.prefecturesBase);
    },
    fields () {
      let fields = this.fieldsBase;
      fields = [
        { key: 'name',         label: '氏名',       sortable: true },
        { key: 'floor',        label: 'フロア',     sortable: true },
        { key: 'room',         label: '部屋名',     sortable: true },
        { key: 'control',      label: 'アクション', class: 'control' },
      ];
      return fields;
    },
    filteredItems () {
      let filteredItems = _.clone(this.items);

      // 氏名フィルタ
      if (this.name !== '') {
        filteredItems = _.filter(filteredItems, (item) => {
          return item.name.indexOf(this.name) !== -1 || item.nameKana.indexOf(this.name) !== -1;
        });
      }

      // フロアフィルタ
      if (this.floor !== undefined) {
        // console.log(filteredItems);
        filteredItems = _.filter(filteredItems, (item) => {
          return item.floor.name === this.floor.name;
        });
      }
      return filteredItems;
    },
  },
  watch: {
    users () {
      this.updateItems();
    },
  },
  methods: {
    updateItems () {
      let items = [];
      let floors = {};

      _.each(this.users, (user) => {
        let floor = NO_FLOOR;
        if (!_.isEmpty(user.lastNameKana)) {
          floor = { name: user.lastNameKana, nameKana: user.lastNameKana };
          // フロアリストに追加
          floors[floor.name] = floor;
        }

        // テーブル1行データの生成
        items.push({
          id: user.id,
          name: user.firstName,
          nameKana: user.firstNameKana,
          room: user.lastName,
          prefecture: user.addressPrefecture,
          municipality: user.addressMunicipality,
          floor: floor,
        });
      });
      // フロアフィルタ
      floors = _.values(floors);
      floors.sort(function (a, b) {
        if (a.nameKana < b.nameKana) return -1;
        if (a.nameKana > b.nameKana) return 1;
        return 0;
      });
      // Vueプロパティ更新
      this.items = items;
      this.floors = floors;
    },
    sortItems (a, b, sortBy) {
      switch (sortBy) {
        case 'name':
          if (a.nameKana < b.nameKana) return -1;
          if (a.nameKana > b.nameKana) return 1;
          return 0;
        case 'floor':
          if (a.floor.nameKana < b.floor.nameKana) return -1;
          if (a.floor.nameKana > b.floor.nameKana) return 1;
          return 0;
        default:
          if (a[sortBy] < b[sortBy]) return -1;
          if (a[sortBy] > b[sortBy]) return 1;
          return 0;
      }
    },
    add () {
      this.$refs.userSettingDialog.show(null);
    },
    edit (item) {
      let user = this.users[item.id];
      this.$refs.userSettingDialog.show(user);
    },
    remove (item) {
      let user = this.users[item.id];
      this.$refs.deleteUserDialog.show(user);
    },
    editAlert (item) {
      let user = this.users[item.id];
      this.$refs.alertSettingDialog.show(user);
    },
    showDetail (item) {
      this.$router.push('/detail/' + item.id);
    },
    clearFilter () {
      this.name = '';
      this.prefecture = NO_FILTER;
      this.municipality = '';
      this.floor = undefined;
    },
  },
  created () {
    this.updateItems();
  },
};
</script>

<style scoped>
.jsp-manage-user-screen {
  padding-top: 16px;
  padding-bottom: 16px;
}

.search-area {
  border-radius: 16px;
  padding: 16px;
  margin: 16px 0;
  background-color: white;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
}
.label {
  line-height: 30px;
  text-align: right;
}
.search-area-button {
  padding: 0;
}

.control-extra {
  padding-right: 8px;
}
.control-extra > * + * {
  margin-left: 8px;
}

table {
  margin-top: 8px;
  background-color: white;
}
>>> th {
  background-color: #9de198;
}
>>> td:not(.control) {
  line-height: 28px;
}

.jsp-button + .jsp-button {
  margin-left: 12px;
}
</style>
