<template>
  <div class="jsp-user-name" :class="size">
    <span class="name">{{ name }}</span>
    <span class="honorific">様</span>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    name: { type: String, require: true },
    size: {
      type: String,
      default: 'md',
      validator: (val) => {
        return ['md', 'lg'].includes(val);
      },
    }
  },
};
</script>

<style scoped>
.jsp-user-name {
  color: #646464;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
}
.name {
  font-weight: bold;
}
.md > .name {
  font-size: 16px;
}
.md > .honorific {
  font-size: 12px;
}
.lg > .name {
  font-size: 24px;
}
.lg > .honorific {
  font-size: 18px;
  margin-left: 4px;
}
</style>
