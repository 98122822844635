<template>
  <div class="jsp-health-summary">
    <div class="breath">
      <Icon icon="breath-line" size="xs" />
      <span class="value">{{ breath }}</span>
    </div>
    <div class="divider"></div>
    <div class="heart">
      <Icon icon="heart-line" size="xs" />
      <span class="value">{{ heart }}</span>
    </div>
  </div>
</template>

<script>
import Icon from '@/view/common/Icon';

export default {
  components: {
    Icon,
  },
  props: {
    value: { type: Object, require: true },
  },
  computed: {
    breath () {
      if (this.value.breath === Constants.EMPTY_VALUE) {
        return '-';
      }
      return this.value.breath;
    },
    heart () {
      if (this.value.heart === Constants.EMPTY_VALUE) {
        return '-';
      }
      return this.value.heart;
    },
  },
};
</script>

<style scoped>
.jsp-health-summary {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 74px;
}
.jsp-health-summary > div {
  text-align: left;
}
.breath {
  color: #67c7d4;
}
.heart {
  color: #ff7c53;
}
.divider {
  height: 1px;
  background-color: #9b9b9b ;
}
.jsp-icon {
  vertical-align: text-top;
  margin-left: 4px;
}
.value {
  display: inline-block;
  width: 30px;
  font-size: 21px;
  text-align: center;
  overflow-x: visible;
}
.unit {
  font-size: 80%;
}
</style>
