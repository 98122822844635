<template>
  <div class="jsp-user-group-item" :class="color" @click="moveToDetail">
    <UserName :name="user.name" />
    <StatusIcon :user="user" inline />
    <HealthSummary v-if="user.haveBedSensor" :value="user.bed" />
    <BathingTimeCounter v-if="isShowBathTimeCounter" :user="user" />
    <StatusText :user="user" />
    <b-badge v-if="alertCount > 0" pill variant="danger">!</b-badge>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import UserEntity from '@/model/entity/UserEntity';
import UserName from '@/view/common/UserName';
import StatusIcon from '@/view/common/StatusIcon';
import StatusText from '@/view/common/StatusText';
import BathingTimeCounter from '@/view/common/BathingTimeCounter';
import HealthSummary from '@/view/user-list/HealthSummary';

export default {
  components: {
    UserName,
    StatusIcon,
    StatusText,
    BathingTimeCounter,
    HealthSummary,
  },
  props: {
    user: { type: UserEntity, require: true },
  },
  computed: {
    color () {
      if (this.user.alerts.length > 0) {
        return 'alerting';
      }
      return Constants.STATUS_ICON[this.user.status];
    },
    breath () {
      if (!_.isNumber(this.user.bed.breath)) return '-';
      if (this.user.bed.breath === Constants.EMPTY_VALUE) return '-';
      return this.user.bed.breath;
    },
    heart () {
      if (!_.isNumber(this.user.bed.heart)) return '-';
      if (this.user.bed.heart === Constants.EMPTY_VALUE) return '-';
      return this.user.bed.heart;
    },
    alertCount () {
      return this.user.unconfirmedAlerts.length;
    },
    isShowBathTimeCounter () {
      if (this.user.haveBedSensor &&
          this.user.status !== Constants.STATUS_NOT_FOUND_BED &&
          this.user.status !== Constants.STATUS_NO_GATEWAY &&
          this.user.status !== Constants.STATUS_NO_SENSOR &&
          this.user.status !== Constants.STATUS_NO_DATA) {
        return true;
      }
      return false;
    }
  },
  methods: {
    ...mapActions('User', [
      'selectUser',
    ]),
    moveToDetail () {
      this.$router.push('/detail/' + this.user.id);
    },
  },
};
</script>

<style scoped>
.jsp-user-group-item {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 180px;
  height: 150px;
  padding: 10px;
  margin: 8px 8px 0 0;
  border-radius: 12px 0 12px 0;
  background-color: white;
  text-align: center;
  cursor: pointer;
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.25);
}
.jsp-user-group-item::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  border-radius: 12px 0 12px 0;
}
.jsp-user-group-item:hover::before {
  background-color: rgba(0, 0, 0, 0.05);
}
.jsp-user-group-item:active::before {
  background-color: transparent;
}
.alerting {
  background-color: #ffc2c2;
}
.no-data {
  background-color: #dbdbdb;
}
.jsp-user-name {
  /* margin-bottom: 8px; */
}
.jsp-status-text {
  /* margin-top: 8px; */
}
.value {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 8px;
  margin: 0 auto;
  text-align: center;
  font-size: 21px;
}
.value > .breath,
.value > .heart {
  display: inline-block;
  vertical-align: middle;
  width: 36px;
}
.value > .breath {
  color: #415492;
}
.value > .heart {
  color: #d75251;
}
.alerting > .value >>> *,
.alerting > .jsp-health-summary,
.alerting > .jsp-health-summary >>> * {
  fill: #d75251 !important;
  color: #d75251 !important;
}
.alerting > .jsp-health-summary >>> .divider {
  background-color: #d75251 !important;
}
.no-data > .value >>> *,
.no-data > .jsp-health-summary,
.no-data > .jsp-health-summary >>> * {
  fill: #9b9b9b !important;
  color: #9b9b9b !important;
}

.badge {
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 16px;
}
</style>
