<template>
  <div class="jsp-history-list">
    <template v-if="histories">
      <div class="guide" v-if="histories.length !== 0">
        <span v-for="i in 3" :key="i">
          {{ ((i - 1) * 15)}}
        </span>
      </div>
      <div v-if="histories.length === 0" class="empty-state">
        入浴履歴がありません。
      </div>
      <VueCustomScrollbar class="jsp-history-list-items" :class="{ down: down }" :settings="{ wheelPropagation: false }">
        <HistoryListItem
          v-for="history in histories" :key="history.date"
          :history="history"
          :select="isSelect(history)"
          @click.native="select(history)"
        />
      </VueCustomScrollbar>
    </template>
    <Loader :loading="true" v-else>
      データ取得中...
    </Loader>
  </div>
</template>

<script>
import DetailEntity   from '@/model/entity/DetailEntity';
import HistoryEntity   from '@/model/entity/HistoryEntity';
import HistoryListItem from '@/view/user-detail/HistoryListItem';
import VueCustomScrollbar from 'vue-custom-scrollbar';
import Loader          from '@/view/common/Loader';

export default {
  components: {
    HistoryListItem,
    VueCustomScrollbar,
    Loader,
  },
  props: {
    histories: { type: Array, require: true },
    selected: { type: HistoryEntity },
    detail: { type: DetailEntity, require: true },
    sleepTimeVisible: { type: Boolean, default: true },
    down: { type: Boolean, default: false }
  },
  data () {
    return {
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    select (history) {
      if (this.selected === history) return;
      this.$emit('select', history);
    },
    isSelect (history) {
      if (_.isUndefined(this.selected)) {
        return false;
      }
      return this.selected.date === history.date;
    }
  },
  created () {
  },
  mounted () {
  },
  destroyed () {
  },
};
</script>

<style scoped>
.jsp-history-list {
  position: relative;
  display: flex;
  flex-direction: column;
}
.jsp-history-list-items {
  position: relative;
  height: calc(100vh - 135px);
  transition: height 300ms ease;
}
.jsp-history-list-items.down {
  height: calc(100vh - 355px);
}
.legend {
  position: absolute;
  top: -20px;
  right: 0;
  font-size: 14px;
}
.legend > .color {
  margin-left: 8px;
  font-size: 24px;
  line-height: 14px;
}
.legend > .history-sleep > svg {
  margin: 2px 2px 0 0;
  color: blue;
}
.guide {
  position: relative;
  margin: 0px 40px 0px 100px;
  white-space: nowrap;
}
.guide > span {
  display: inline-block;
  vertical-align: top;
  width: 20px;
  text-align: center;
}
.guide > span + span {
  margin-left: calc((100% - 20px * 3) / 2);
}
/* .data {
  position: relative;
  flex: 1;
} */
/* .data > div > div {
  position: relative;
} */
.jsp-history-list-item + .jsp-history-list-item {
  margin-top: 4px;
}
.jsp-sleep-time-chart {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50px;
}
.empty-state {
  font-weight: bold;
  color: #61c359;
}
</style>
