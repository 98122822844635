import moment from 'moment';
import WebSocket from '@/model/websocket/WebSocket';
import Store from '@/model/store/Store';
import Messages from '@/common/Messages';
import UserEntity from '@/model/entity/UserEntity';
import SensorSettingEntity from '@/model/entity/sensor-setting/SensorSettingEntity';
import BedSensorSettingEntity from '@/model/entity/sensor-setting/BedSensorSettingEntity';
import BathSettingEntity from '@/model/entity/BathSettingEntity';
import StaffEntity from '@/model/entity/StaffEntity';
import AlertEntity from '@/model/entity/AlertEntity';
import GatewayEntity from '@/model/entity/GatewayEntity';
import SensorEntity from '@/model/entity/SensorEntity';
import RealtimeEntity from '@/model/entity/RealtimeEntity';
import DetailEntity from '@/model/entity/DetailEntity';
import HistoryEntity from '@/model/entity/HistoryEntity';

let cl6IntervalId = false;
let cl11IntervalId = false;

let wsConnected = false;
let wsConnectionCallback = false;

function toUserEntity (json) {
  let user = new UserEntity();
  user.id                  = json.id;
  user.firstName           = json.firstName;
  user.firstNameKana       = json.firstNameKana;
  user.lastName            = json.lastName;
  user.lastNameKana        = json.lastNameKana;
  user.status              = json.status;
  user.bed.breath          = (json.bed || user.bed).breath;
  user.bed.heart           = (json.bed || user.bed).heart;
  user.bed.move            = (json.bed || user.bed).move;
  user.alerts              = _.map_(json.alerts, toAlertEntity);
  user.unconfirmedAlerts   = _.map_(json.unconfirmedAlerts, toAlertEntity);
  generateAlertId(user.alerts);
  generateAlertId(user.unconfirmedAlerts);
  user.addressPrefecture   = json.addressPrefecture;
  user.addressMunicipality = json.addressMunicipality;
  user.addressOther        = json.addressOther;
  user.gateway             = json.gwid;       // EntityではなくID
  user.sensors             = json.sensors;    // EntityではなくID
  user.staffs              = json.staffs;     // EntityではなくID
  user.sensorSettings      = toSensorSettingEntities(json.sensorSettings);
  user.bath                = _.isObject(json.bath) ? toHistoryEntity(json.bath) : new HistoryEntity();
  return user;
}

function generateAlertId (alerts) {
  _.each(alerts, (alert) => {
    let id = 'a' + alert.type + alert.time;
    let i = 0;
    alert.id = id;
    while (_.isObject(alerts[alert.id])) {
      alert.id = id + i++;
    }
    alerts[alert.id] = alert;
  });
}

function toSensorSettingEntities (sensorSettings) {
  if (_.isUndefined(sensorSettings)) return undefined;
  // センサーごとにループ
  let ret = [];
  _.each(sensorSettings, (settings, sensorCode) => {
    // センサ設定Entityを生成
    let settingsObj = {};
    if (sensorCode === Constants.SENSOR_BED) {
      // ベッドセンサのみ対応
      settingsObj = BedSensorSettingEntity.clone(settings);
    }
    let sensor = new SensorSettingEntity();
    sensor.code = sensorCode;
    sensor.setting = settingsObj;
    ret.push(sensor);
  });
  return ret;
}

function toStaffEntity (json) {
  let staff = new StaffEntity();
  staff.id                 = json.id;
  staff.firstName          = json.firstName;
  staff.firstNameKana      = json.firstNameKana;
  staff.lastName           = json.lastName;
  staff.lastNameKana       = json.lastNameKana;
  staff.listGroupMode      = json.listGroupMode;
  staff.admin              = json.admin;
  staff.loginMail          = json.loginMail;
  staff.notifyMails        = json.notifyMails;
  staff.needChangePassword = json.needChangePassword;
  return staff;
}

function toRealtimeEntity (json) {
  // リアルタイム波形情報があれば
  if (_.isObject(json.realtime)) {
    if (_.isObject(json.realtime.bed)) {
      // リアルタイム波形情報をEntity化
      let realtime = new RealtimeEntity();
      realtime.bed.breath = json.realtime.bed.breath || [];
      realtime.bed.heart = json.realtime.bed.heart || [];
      realtime.bed.move = json.realtime.bed.move || [];
      return realtime;
    }
  }

  return undefined;
}

function toDetailEntity (json) {
  let detail = new DetailEntity();

  // アラート履歴
  detail.alerts = _.map_(json.alerts, toAlertEntity);

  return detail;
}

function toHistoryEntity (json) {
  let history = new HistoryEntity();
  history.date  = json.dateTime;
  history.month     = +moment(json.dateTime).date(1).startOf('date');
  history.batheTime = _.isNumber(json.batheTime) ? json.batheTime : null;
  history.batheFlg = _.isBoolean(json.batheFlg) ? json.batheFlg : false;
  history.alert = _.isBoolean(json.alert) ? json.alert : false;
  return history;
}

function toAlertEntity (json) {
  let alert = new AlertEntity();
  alert.time      = json.time;
  alert.type      = json.type;
  alert.confirmed = json.confirmed;
  return alert;
}

function toGatewayEntity (json) {
  let gateway = new GatewayEntity();
  gateway.id      = json.gwid;
  gateway.name    = json.gwid;   // ゲートウェイIDは名前としても使用する
  gateway.sensors = _.map_(json.sensors, toSensorEntity);
  return gateway;
}

function toBathSettingEntity (json) {
  let setting = BathSettingEntity.clone(JSON.parse(json.bathSetting));
  return setting;
}

function toSensorEntity (json) {
  let sensor = new SensorEntity();
  sensor.id   = json.id;
  sensor.type = json.type;
  // センサーIDを種別ラベルと装置番号ラベルに変換
  let [type, no] = sensor.id.split('_');
  sensor.typeName = `${Constants.SENSOR_NAME[type]}-${no}`;
  return sensor;
}

function logoutResult (result, errorCode) {
  if (result === 'OK') {
    WebSocket.disconnect();
  }
  else {
    Utils.notify({
      type: 'error',
      text: `ログアウトに失敗しました。(errorCode: ${errorCode})`,
    });
  }
}

export default class Translator {

  static init (authToken, callback) {
    wsConnectionCallback = callback;
    WebSocket.connect(authToken);
  }

  static connected () {
    // 5分おきにセッション有効期限を延長する
    Translator.startToExtendSessionExpirationTime();

    // WebSocket接続フラグを立てる
    wsConnected = true;
    // 1秒後、接続が維持されていればStateStoreに接続状態をコミットする
    // WebSocket接続時に認証エラーが発生すると、一瞬だけ接続状態となってしまうため、その対策。
    setTimeout(() => {
      Store.dispatch('State/setConnected', wsConnected);
      // 接続成功／失敗のコールバック処理を実行
      if (_.isFunction(wsConnectionCallback)) {
        wsConnectionCallback(true);
      }
    }, 1000);
  }

  static disconnected () {
    Log.log();
    // WebSocket接続フラグを倒す
    wsConnected = false;
    Store.dispatch('State/setConnected', wsConnected);

    // 詳細グラフの通知延長処理を停止する
    Translator.stopDetailNotification();

    // セッション有効期限の延長処理を停止する
    Translator.stopToExtendSessionExpirationTime();

    // 接続成功／失敗のコールバック処理を実行
    if (_.isFunction(wsConnectionCallback)) {
      wsConnectionCallback(false);
    }

    // 全Storeデータを初期化する
    Store.dispatch('initialize', { reAuth: false });
  }

  static disconnectedByAuthError () {
    Log.log();
    // WebSocket接続フラグを倒す
    wsConnected = false;
    Store.dispatch('State/setConnected', wsConnected);

    // 詳細グラフの通知延長処理を停止する
    Translator.stopDetailNotification();

    // セッション有効期限の延長処理を停止する
    Translator.stopToExtendSessionExpirationTime();

    // 接続成功／失敗のコールバック処理を実行
    if (_.isFunction(wsConnectionCallback)) {
      wsConnectionCallback(false);
    }

    // 全Storeデータを初期化する
    Store.dispatch('initialize');
    Store.dispatch('Auth/setAuthToken', null);
  }

  static logout () {
    // 詳細グラフの通知延長処理を停止する
    Translator.stopDetailNotification();

    // セッション有効期限の延長処理を停止する
    Translator.stopToExtendSessionExpirationTime();

    // ログアウト要求
    WebSocket.requestCL2(logoutResult);

    // WebSocket切断
    WebSocket.disconnect();

    // 全Storeデータを初期化する
    Store.dispatch('initialize');
    Store.dispatch('Auth/setAuthToken', null);
  }

  static startToExtendSessionExpirationTime () {
    Translator.stopToExtendSessionExpirationTime();

    // 5分おきに延長要求を送信
    cl11IntervalId = setInterval(function () {
      WebSocket.requestCL11();
    }, 5 * 60 * 1000);
  }

  static stopToExtendSessionExpirationTime () {
    clearInterval(cl11IntervalId);
  }

  static requestDetail (user, datetime, period) {
    WebSocket.requestCL4(user.id, datetime, period);
  }

  static requestHistories (user, date) {
    WebSocket.requestCL5(user.id, date);
  }

  static startDetailNotification (user) {
    if (!_.isObject(user)) return;
    WebSocket.requestCL6(user.id, 'START', true);

    // 5分おきに延長要求を送信
    cl6IntervalId = setInterval(function (user) {
      WebSocket.requestCL6(user.id, 'START');
    }, 5 * 60 * 1000, user);
  }

  static stopDetailNotification (user) {
    clearInterval(cl6IntervalId);
    if (!_.isObject(user)) return;
    WebSocket.requestCL6(user.id, 'STOP');
  }

  static setAlertConfirmed (user) {
    if (!_.isObject(user)) return;
    WebSocket.requestCL13(user.id);
  }

  static addUser (user, callback) {
    let payload = {
      type: 'ADD',
      firstName:           user.firstName,
      lastName:            user.lastName,
      firstNameKana:       user.firstNameKana,
      lastNameKana:        user.lastNameKana,
      addressPrefecture:   user.addressPrefecture,
      addressMunicipality: user.addressMunicipality,
      addressOther:        user.addressOther,
      gwid:                (user.gateway || {}).id,
      sensors:             _.map_(user.sensors, (sensor) => sensor.id),
      staffs:              _.map_(user.staffs, (staff) => staff.id),
    };
    WebSocket.requestCL7(payload, callback);
  }

  static removeUser (user, callback) {
    let payload = {
      type: 'REMOVE',
      userId: user.id,
    };
    WebSocket.requestCL7(payload, callback);
  }

  static fixUser (user, callback) {
    let payload = {
      type: 'FIX',
      userId:              user.id,
      firstName:           user.firstName,
      lastName:            user.lastName,
      firstNameKana:       user.firstNameKana,
      lastNameKana:        user.lastNameKana,
      addressPrefecture:   user.addressPrefecture,
      addressMunicipality: user.addressMunicipality,
      addressOther:        user.addressOther,
      gwid:                (user.gateway || {}).id,
      sensors:             _.map_(user.sensors, (sensor) => sensor.id),
      staffs:              _.map_(user.staffs, (staff) => staff.id),
    };
    WebSocket.requestCL7(payload, callback);
  }

  static updateSensorSetting (target, sensorSetting, callback) {
    let payload = {
      user: target.user,
      hgw: target.hgw,
      sensor: target.sensor,
      sensorSetting: sensorSetting.setting,
    };
    WebSocket.requestCL17(payload, callback);
  }

  static requestBathSetting (gateway) {
    let payload = {
      hgw: gateway
    };
    WebSocket.requestCL20(payload);
  }

  static updateBathSetting (target, setting, callback) {
    let payload = {
      hgw: target.hgw,
      bathSetting: setting,
    };
    WebSocket.requestCL21(payload, callback);
  }

  static sensorReset (target, callback) {
    let payload = {
      user: target.user,
      hgw: target.hgw,
      sensor: target.sensor,
    };
    WebSocket.requestCL19(payload, callback);
  }

  static addStaff (staff, callback) {
    let payload = {
      type: 'ADD',
      firstName:     staff.firstName,
      firstNameKana: staff.firstNameKana,
      lastName:      staff.lastName,
      lastNameKana:  staff.lastNameKana,
      admin:         staff.admin,
      loginMail:     staff.loginMail,
      notifyMails:   staff.notifyMails,
    };
    WebSocket.requestCL8(payload, callback);
  }

  static removeStaff (staff, callback) {
    let payload = {
      type: 'REMOVE',
      staffId: staff.id,
    };
    WebSocket.requestCL8(payload, callback);
  }

  static fixStaff (staff, callback) {
    let payload = {
      type: 'FIX',
      staffId:       staff.id,
      firstName:     staff.firstName,
      firstNameKana: staff.firstNameKana,
      lastName:      staff.lastName,
      lastNameKana:  staff.lastNameKana,
      admin:         staff.admin,
      loginMail:     staff.loginMail,
      notifyMails:   staff.notifyMails,
    };
    WebSocket.requestCL8(payload, callback);
  }

  static initStaffPassword (staff, callback) {
    WebSocket.requestCL9(staff.id, callback);
  }

  static updateListGroupMode (staff, listGroupMode) {
    let payload = {
      type: 'FIX',
      staffId: staff.id,
      listGroupMode,
    };
    WebSocket.requestCL8(payload);
  }

  static updateStaffSetting (staff, password, salt, notifyMails, sendTestMail, callback) {
    let payload = {
      type: 'FIX',
      staffId: staff.id,
      notifyMails,
      sendTestMail,
      password,
      salt,
    };
    WebSocket.requestCL8(payload, callback);
  }

  static updatePassword (staff, password, salt) {
    let payload = {
      type: 'FIX',
      staffId: staff.id,
      password,
      salt,
    };
    WebSocket.requestCL8(payload);
  }

  static hookError (json, req) {
    // CL6通知制御電文だけ、ページ表示中に何度もリクエストが飛ぶため、
    // 初回リクエストでなければエラーを無視する
    if (json.head.id === 6 && (!_.isObject(req) || !req.initial)) return;

    // エラーコードをメッセージに変換し、通知する
    Log.warn(json, req);
    Utils.notify({
      type: 'error',
      text: Messages.get(json.errorCode).msg,
      duration: 10000,
    });
  }

  static translateCL3Notify (json) {
    // 「未定義のスタッフが担当にセットされる」などを防ぐために、
    // 被参照データから順に処理する
    // ゲートウェイ一覧データをEntity化⇒Storeへコミット
    if (_.isArray(json.gateways)) {
      let gateways = _.map(json.gateways, toGatewayEntity);
      Store.dispatch('Gateway/updateGateways', gateways);
    }

    // スタッフ一覧データをEntity化⇒Storeへコミット
    if (_.isArray(json.staffs)) {
      let staffs = _.map(json.staffs, toStaffEntity);
      Store.dispatch('Staff/updateStaffs', staffs);
    }

    // ユーザー一覧データをEntity化⇒Storeへコミット
    if (_.isArray(json.users)) {
      let users = _.map(json.users, toUserEntity);
      users.singleUser = json.users.singleUser;
      Store.dispatch('User/updateUsers', users);
    }

    // ログインスタッフ情報をStoreへコミット
    if (!_.isUndefined(json.loginStaffId)) {
      Store.dispatch('Staff/updateLoginStaff', json.loginStaffId);
    }

    // 施設情報をStoreへコミット
    if (!_.isUndefined(json.facilityName)) {
      Store.dispatch('Staff/updateFacilityName', json.facilityName);
    }
    if (!_.isUndefined(json.facilityMode)) {
      Store.dispatch('Staff/updateFacilityMode', json.facilityMode);
    }

    // 初期データの受信を完了し、画面表示の準備が整ったとフラグを立てる
    // これを立てることで、 router.js で止めていた画面遷移が先に進むようになる
    Store.dispatch('State/setReady');
  }

  static translateCL4Response (json) {
    // 参照エラーを起こさないために undefined はオブジェクトに置換
    if (_.isUndefined(json.detail)) json.detail = {};

    // Entityへ変換
    let detail = toDetailEntity(json);

    // Storeへコミット
    Store.dispatch('Record/setDetail', detail);
  }

  static translateCL4Notify (json) {
    if (_.isUndefined(_.isUndefined(json.id))) {
      Log.warn('CL4 must be specified "id" property. skipped.');
      return;
    }

    // リアルタイムデータがあれば処理
    if (_.isObject(json.realtime)) {
      // リアルタイム波形情報をEntity化
      let realtime = toRealtimeEntity(json);

      // Storeへコミット
      if (_.isObject(realtime)) {
        Store.dispatch('Realtime/update', realtime);
      }
    }
  }

  static translateCL5Response (json) {
    // データがなければ無視
    if (_.isUndefined(json.histories)) {
      Log.warn('CL5 must be specified "histories" property. skipped.');
      return;
    }

    // Entityに変換
    let histories = _.map(json.histories, toHistoryEntity);

    // Storeへコミット
    Store.dispatch('Record/setHistories', histories);
  }

  static translateCL5Notify (json) {
    // データがなければ無視
    if (_.isUndefined(json.histories)) {
      Log.warn('CL5 must be specified "histories" property. skipped.');
      return;
    }

    // Entityに変換
    let histories = _.map(json.histories, toHistoryEntity);
    // Storeへコミット
    Store.dispatch('Record/updateHistories', histories);
  }

  static translateCL6Response (json) {
  }

  static translateCL7Response (json, callback) {
    if (!_.isFunction(callback)) return;
    callback(json.result === 'OK');
  }

  static translateCL8Response (json, callback) {
    if (!_.isFunction(callback)) return;
    callback(json.result === 'OK');
  }

  static translateCL9Response (json, callback) {
    if (!_.isFunction(callback)) return;
    callback(json.result === 'OK');
  }

  static translateCL12Response (json) {
    // 過去データの年月一覧を受信
    Store.dispatch('Record/setMonths', json.dates);
  }

  static translateCL13Response (json) {
    // TODO
  }

  static translateCL17Response (json, callback) {
    if (!_.isFunction(callback)) return;
    callback(json.result === 'OK');
  }

  static translateCL19Response (json, callback) {
    if (!_.isFunction(callback)) return;
    callback(json.result === 'OK');
  }

  static translateCL20Response (json) {
    Store.dispatch('Gateway/setBathSetting', toBathSettingEntity(json));
  }

  static translateCL21Response (json, callback) {
    if (!_.isFunction(callback)) return;
    callback(json.result === 'OK');
  }
}
