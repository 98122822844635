<template>
  <h5 class="jsp-title" >{{ facilityName }}</h5>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters('Staff', [
      'facilityName',
    ]),
  },
  methods: {
    toggleFullscreen: function () {
      if (this.isFullscreen()) {
        this.exitFullscreen();
      }
      else {
        this.enterFullscreen();
      }
    },
    isFullscreen: function () {
      return (
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement
      );
    },
    enterFullscreen: function () {
      let list = [
        'requestFullscreen',
        'webkitRequestFullScreen',
        'mozRequestFullScreen',
        'msRequestFullscreen'
      ];
      let num = list.length;
      let element = $('body > div')[0];
      for (let i = 0; i < num; i++) {
        if (element[list[i]]) {
          element[list[i]]();
          return true;
        }
      }
      return false;
    },
    exitFullscreen: function () {
      let list = [
        'exitFullscreen',
        'webkitExitFullscreen',
        'mozCancelFullScreen',
        'msExitFullscreen'
      ];
      let num = list.length;
      for (let i = 0; i < num; i++) {
        if (document[list[i]]) {
          document[list[i]]();
          return true;
        }
      }
      return false;
    },
  },
};
</script>

<style scoped>
.jsp-title {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  line-height: 40px;
  padding: 0 16px;
  margin: 0;
  cursor: pointer;
}
</style>
