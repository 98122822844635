<template>
  <div class="jsp-status-time" :class="[color, { inline }, size]">
    {{ time }}
  </div>
</template>

<script>
import UserEntity from '@/model/entity/UserEntity';
import { mapGetters } from 'vuex';
import moment from 'moment';

export default {
  components: {
  },
  props: {
    user: { type: UserEntity, require: true },
    inline: { type: Boolean, default: false },
    size: { type: String, default: '' },
  },
  computed: {
    ...mapGetters('Record', {
      histories: 'histories'
    }),
    color () {
      try {
        if (this.user.alerts.length > 0) {
          return Constants.STATUS_COLOR_ALERT;
        }
        return Constants.STATUS_COLOR[this.user.status] || '';
      }
      catch (e) {
        return '';
      }
    },
    time () {
      let time = '--:--:--';
      if (_.isNull(this.user)) {
        return time;
      }
      if (_.isObject(this.user.bath)) {
        if (this.isBathe && this.user.bath.batheFlg) {
          let batheTime = this.user.bath.batheTime;
          let sec = (batheTime % 60) % 60;
          let min = Math.floor(batheTime / 60) % 60;
          let hour = Math.floor(batheTime / 3600);
          time = moment({ hours: hour, minutes: min, seconds: sec }).format('HH:mm:ss');
        }
      }
      return time;
    },
    isBathe () {
      return this.user.status === 1 || this.user.status === 2 || this.user.status === 3 || this.user.status === 4;
    }
  },
};
</script>

<style scoped>
.jsp-status-time {
  text-align: center;
  font-size: 15px;
  font-weight: bold;
}
.inline {
  display: inline-block;
  vertical-align: middle;
  text-align: left;
}
.lg {
  font-size: 125%;
}
.xl {
  font-size: 150%;
}
</style>
