import Translator from '@/model/websocket/Translator';
import UserGroupEntity from '@/model/entity/UserGroupEntity';
import AlertEntity from '@/model/entity/AlertEntity';
import Store from '@/model/store/Store';

export default {
  namespaced: true,
  state: {
    users: [],
    selectedUser: null,
    dummyFloor: { id: 0, name: Constants.FLOOR_NONE },
    dummyStaff: { expandGroup: true },
  },
  getters: {
    users (state, getters, rootState, rootGetters) {
      return state.users;
    },
    alertUsers (state, getters, rootState, rootGetters) {
      return _.filter(state.users, (user) => user.unconfirmedAlerts.length > 0);
    },
    groups (state, getters, rootState, rootGetters) {
      let groupMap = {};
      let atherFloorGroup = new UserGroupEntity();
      atherFloorGroup.id = state.dummyFloor.id;
      atherFloorGroup.name = state.dummyFloor.name;
      atherFloorGroup.staff = state.dummyStaff;
      // フロアごとにループして振り分ける
      let groupCount = {}; // 同じフロアIDの数
      let floorGroup = {}; // フロア名の数（15区切り
      let users = state.users.sort(function (a, b) {
        if (Number(a.lastName) < Number(b.lastName)) return -1;
        if (Number(a.lastNameKana) < Number(b.lastNameKana)) return -1;
        return 1;
      });
      _.each(users, function (user) {
        if (!_.isEmpty(user.lastNameKana)) {
          // フロア数計算
          let floorName = user.lastNameKana;
          if (_.isUndefined(groupCount[floorName])) {
            // 同じフロアが無ければカウント１
            groupCount[floorName] = 1;
            floorGroup[floorName] = 1;
          }
          else {
            // 同じフロアが既にあればカウントアップ
            groupCount[floorName]++;
            if (groupCount[floorName] > 100) {
              // 同じフロアが100以上ならフロア名を区切る
              groupCount[floorName] = 1;
              floorGroup[floorName]++;
            }
          }
          let floorId = `${floorName}${floorGroup[floorName]}`;
          // グループがなければ作成
          if (_.isUndefined(groupMap[floorId])) {
            let floorGroupName = `フロア ${floorName}-${floorGroup[floorName]}`;
            let staff = user.staffs[0] || state.dummyStaff;
            let group = new UserGroupEntity();
            group.id = floorId;
            group.name = floorGroupName;
            group.staff = staff;
            groupMap[floorId] = group;
          }
          // グループにユーザーを追加
          groupMap[floorId].users.push(user);
        }
        else {
          // フロアが未設定の場合
          atherFloorGroup.users.push(user);
        }
      });
      // 配列に変換して返却
      let groups = _.values(groupMap);
      groups.sort(function (a, b) {
        if (a.id < b.id) return -1;
        return 1;
      });
      // 未設定があればマップに追加
      if (atherFloorGroup.users.length > 0) {
        let atherFloorUsers = _.chunk(atherFloorGroup.users, 15);
        _.each(atherFloorUsers, function (users) {
          let afg = new UserGroupEntity();
          afg.id = state.dummyFloor.id;
          afg.name = state.dummyFloor.name;
          afg.staff = state.dummyStaff;
          afg.users = users;
          groups.push(afg);
        });
      }
      return groups;
    },
    selectedUser (state, getters, rootState, rootGetters) {
      return state.selectedUser;
    },
    usedGateways (state, getters, rootState, rootGetters) {
      let gateways = [];
      _.each(state.users, (user) => {
        if (user.gateway) gateways.push(user.gateway);
      });
      return _.uniq(gateways);
    },
    usedSensors (state, getters, rootState, rootGetters) {
      let sensors = [];
      _.each(state.users, (user) => {
        _.each(user.sensors, (sensor) => {
          sensors.push(sensor);
        });
      });
      return _.uniq(sensors);
    },
    floors (state) {
      let floors = [];
      _.each(state.users, function (user) {
        if (!_.isEmpty(user.lastNameKana)) {
          floors.push(user.lastNameKana);
        }
      });
      return _.uniq(floors);
    }
  },
  mutations: {
    updateUsers (state, payload) {
      let { users, gateways, staffs } = payload;
      let news = users;
      let olds = state.users;
      let renewal = [];   // 変更、新規追加されたユーザーはこの配列へ格納
      // IDをキーにした連想配列に変換
      _.each(news, (_new) => (news[_new.id] = _new));

      // ユーザーの追加・更新
      _.each(news, (_new) => {
        let _old = olds[_new.id];
        // ユーザーの追加
        if (_.isUndefined(_old)) {
          renewal.push(_new);
          renewal[_new.id] = _new;

          // 空値をセット
          let keys = ['alerts', 'unconfirmedAlerts', 'sensorSettings'];
          _.each(keys, (key) => {
            if (_.isUndefined(_new[key])) {
              _new[key] = [];
            }
          });
        }

        // ユーザーの更新
        else {
          renewal.push(_old);
          renewal[_old.id] = _old;
          copyUserProperty(_old, _new);
          let diff = mergeUnconfirmedAlerts(_new, _old);

          // 画面を開いているユーザならアラート一覧を更新する
          if (state.users.indexOf(state.selectedUser) !== -1) {
            // アラート情報が増えた時
            if (state.selectedUser.id === _new.id && diff.length !== 0) {
              // RecordStoreに追加する
              Store.dispatch('Record/addNotifyAlert', diff);
            }
          }
        }

        // 氏名、かな氏名を更新
        let user = _.last(renewal);
        user.name = user.lastName + ' ' + user.firstName;
        user.nameKana = user.lastNameKana + ' ' + user.firstNameKana;

        // ゲートウェイをIDからEntityへ変換
        if (_.isString(user.gateway)) {
          user.gateway = gateways[user.gateway];
        }
        if (!_.isObject(user.gateway)) {
          user.gateway = null;
        }

        // アラートを発生日時降順にソート
        user.alerts.sort((a, b) => (b.time - a.time));

        // 音を停止した未確認アラートの状態を復元
        let latest = loadLatestConfirmedAlertTime(user);
        _.each(user.unconfirmedAlerts, (alert) => {
          if (alert.time <= latest) {
            alert.confirmed = true;
          }
        });

        // センサーIDがあれば更新
        if (_.isArray(user.sensors)) {
          if (user.sensors.length > 0) {
            // センサーをIDからEntityへ変換
            _.each(user.sensors, (sensor, i) => {
              if (_.isString(sensor)) {
                let gateway = gateways[user.gateway.id];
                user.sensors[i] = gateway.sensors[sensor];
              }

              // センサー有無を判定
              switch (user.sensors[i].type) {
                case Constants.SENSOR_BED:
                  user.haveBedSensor = true;
                  break;
                case Constants.SENSOR_LIVING:
                  user.haveLivingSensor = true;
                  break;
                case Constants.SENSOR_TOILET:
                  user.haveToiletSensor = true;
                  break;
              }
            });
          }

          // センサー種別をインデックスとして使用できるように設定
          _.each(user.sensors, (sensor) => {
            user.sensors[`_${sensor.type}`] = sensor;
          });
        }

        // スタッフIDがあれば更新
        if (_.isArray(user.staffs)) {
          if (user.staffs.length > 0) {
            // スタッフをIDからEntityへ変換
            _.each(user.staffs, (staffId, i) => {
              if (_.isString(staffId)) {
                user.staffs[i] = staffs[staffId];
              }
            });
          }
        }
        else {
          user.staffs = [];
        }
      });

      // 名前順にソートして新たな配列をStateに保持
      renewal.sort((a, b) => {
        if (a.nameKana < b.nameKana) return -1;
        if (a.nameKana > b.nameKana) return 1;
        return 0;
      });
      state.users = renewal;
      // 現在詳細画面を開いている利用者が削除された場合は画面遷移させる
      if (state.users.indexOf(state.selectedUser) === -1) {
        state.selectedUser = null;
      }
    },
    updateSingleUser (state, user) {
      copyUserProperty(state.users[user.id], user);
    },
    selectUser (state, user) {
      state.selectedUser = user;
    },
    setAllAlertConfirmed (state) {
      _.each(state.users, (user) => {
        let latest = 0;
        _.each(user.unconfirmedAlerts, (alert) => {
          alert.confirmed = true;
          latest = Math.max(latest, alert.time);
        });
        if (latest > 0) {
          saveLatestConfirmedAlertTime(user, latest);
        }
      });
    },
  },
  actions: {
    initialize (context) {
      context.commit('selectUser', null);
      context.commit('updateUsers', { users: [], gateways: [], staffs: [] });
    },
    updateUsers (context, users) {
      // singleUserフラグが立っている場合は
      // リアルタイム情報として情報が来ているため、1ユーザーだけ更新する
      if (users.singleUser) {
        context.commit('updateSingleUser', users[0]);
      }
      else {
        let { rootGetters } = context;
        let payload = {
          users,
          gateways: rootGetters['Gateway/gateways'],
          staffs: rootGetters['Staff/staffs'],
        };
        context.commit('updateUsers', payload);

        // スタッフ情報にも担当利用者情報を持たせる
        context.dispatch('Staff/updateLinks', context.getters.users, { root: true });
      }
    },
    selectUser (context, userId) {
      let user = context.getters.users[userId];
      context.commit('selectUser', user);
    },
    removeUser (context, payload) {
      Translator.removeUser(payload.user, payload.callback);
    },
    startDetailNotification (context) {
      let user = context.getters.selectedUser;
      if (!_.isObject(user.gateway)) {
        Log.log('Gateway is not linked.');
        return;
      }
      Translator.startDetailNotification(user);
    },
    stopDetailNotification (context) {
      Translator.stopDetailNotification(context.getters.selectedUser);
    },
    applyUser (context, payload) {
      let { user, callback } = payload;
      if (_.isUndefined(user.id)) {
        Translator.addUser(user, callback);
      }
      else {
        Translator.fixUser(user, callback);
      }
    },
    setAllAlertConfirmed (context) {
      context.commit('setAllAlertConfirmed');
    },
    updateSensorSetting (context, payload) {
      let { target, sensorSetting, callback } = payload;
      Translator.updateSensorSetting(target, sensorSetting, callback);
    },
    sensorReset (constext, payload) {
      let { target, callback } = payload;
      Translator.sensorReset(target, callback);
    }
  }
};

function copyUserProperty (to, from) {
  _.each(from, (value, key) => {
    if (key === 'id') return;
    if (key === 'unconfirmedAlerts') return;
    if (_.isUndefined(value)) return;
    switch (key) {
      case 'bed':    copyBedProperty(to, from);    return;
      case 'bath': copyBathProperty(to, from); return;
    }
    to[key] = value;
  });
}

function copyBedProperty (to, from) {
  if (_.isObject(to.bed) === false) {
    to.bed = { breath: Constants.EMPTY_VALUE, heart: Constants.EMPTY_VALUE, move: Constants.EMPTY_VALUE };
  }
  if (_.isNumber(from.bed.breath)) {
    to.bed.breath = from.bed.breath;
  }
  if (_.isNumber(from.bed.heart)) {
    to.bed.heart = from.bed.heart;
  }
  if (_.isNumber(from.bed.move)) {
    to.bed.move = from.bed.move;
  }
}

function copyBathProperty (to, from) {
  if (_.isBoolean(from.bath.alert)) {
    to.bath.alert = from.bath.alert;
  }
  if (_.isBoolean(from.bath.batheFlg)) {
    to.bath.batheFlg = from.bath.batheFlg;
  }
  if (_.isNumber(from.bath.batheTime)) {
    to.bath.batheTime = from.bath.batheTime;
  }
  if (_.isNumber(from.bath.date)) {
    to.bath.date = from.bath.date;
  }
  if (_.isNumber(from.bath.month)) {
    to.bath.month = from.bath.month;
  }
}

function mergeUnconfirmedAlerts (_new, _old) {
  let result = [];
  if (_.isArray(_new.unconfirmedAlerts) === false) return [];
  _.each(_new.unconfirmedAlerts, (alert) => {
    if (_.isObject(_old.unconfirmedAlerts[alert.id]) === false) {
      result.push(AlertEntity.clone(alert));
    }
    else {
      if (_new.unconfirmedAlerts[alert.id].confirmed) return;
      _new.unconfirmedAlerts[alert.id].confirmed = alert.confirmed;
    }
  });
  _old.unconfirmedAlerts = _new.unconfirmedAlerts;
  return result;
}

function saveLatestConfirmedAlertTime (user, time) {
  localStorage.setItem(`latestConfirmedAlertTime-${user.id}`, time);
}

function loadLatestConfirmedAlertTime (user) {
  return localStorage.getItem(`latestConfirmedAlertTime-${user.id}`) || 0;
}
