<template>
  <b-form class="jsp-tab-content" @submit.prevent="showSensorSettingUpdateDialog">
    <b-container class="px-0 py-0" >
      <b-row class="sensor-setting-tab" rows=12 >
        <b-col cols=7 class="setting-area">
          <VueCustomScrollbar class="jsp-setting-list" :settings="{ wheelPropagation: false, suppressScrollX: true }">
            <SensorSettingItem v-for="item in itemList" class="setting-item" :item="item" :type="item.type" :key="item.no" />
          </VueCustomScrollbar>
        </b-col>
        <b-col cols=5 class="other-area">
          <b-row class="memo">
            <span><b>メモ</b></span>
            <b-form-textarea :rows="10" v-model="memo" />
          </b-row>
          <b-row class="buttons">
            <Button color="blue" type="submit" :disabled="!isChange" :loading="processing">センサー設定登録</Button>
            <Button color="blue" :frame="true" @click="hide" :disabled="processing">キャンセル</Button>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <SensorSendConfirmDialog type='sensor' ref="sensorSettingUpdateDialog" />
  </b-form>
</template>

<script>
import BedSensorSettingEntity from '@/model/entity/sensor-setting/BedSensorSettingEntity';
import UserEntity from '@/model/entity/UserEntity';
import VueCustomScrollbar from 'vue-custom-scrollbar';
import SensorSettingItem from '@/view/manage-user/tabs/SensorSettingItem';
import Button from '@/view/common/Button';
import SensorSendConfirmDialog  from '@/view/common/SensorSendConfirmDialog';

const IP_PATTERN = '^(([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\\.){3}([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$';
const STR_PATTERN = '^[\\da-zA-Z-]+$';
const ITEM_LIST = [
  { no: '01', type: 'number', min: 1, max: 9999, name :'装置番号', explain: '[ 1～9999 ]', required: true },
  { no: '02', type: 'number', min: 1, max: 8, name :'機能選択', explain: '[ 1:離床 2:見守り 3:離床・見守 4:ボタン単独 5:ソフトウェア単独\r\n  6:ボタン／ソフト 7:ソフト単独GW 8:ボタン／ソフトGW ]', required: true },
  { no: '03', type: 'number', min: 0, 'max': 99, name:'正常呼吸範囲 下限', explain: '[ 単位(回/分) 0～99 0:無効 ]', required: true },
  { no: '04', type: 'number', min: 0, 'max': 99, name:'正常呼吸範囲 上限', explain: '[ 単位(回/分) 0～99 0:無効 ]', required: true },
  { no: '05', type: 'number', min: 30, 'max': 999, name:'呼吸異常連続時間', explain: '[ 単位(秒) 30～998 999:無効 ]', required: true },
  { no: '06', type: 'number', min: 0, 'max': 999, name:'正常脈拍範囲 下限', explain: '[ 単位(回/分) 1～999 0:無効 ]', required: true },
  { no: '07', type: 'number', min: 0, 'max': 999, name:'正常脈拍範囲 上限', explain: '[ 単位(回/分) 1～999 0:無効 ]', required: true },
  { no: '08', type: 'number', min: 30, 'max': 999, name:'脈拍異常連続時間', explain: '[ 単位(秒) 30～998 999:無効 ]', required: true },
  { no: '09', type: 'number', min: 0, 'max': 99, name:'報知後解除時間', explain: '[ 単位(秒) 1～98 0,99:無効 ]', required: true },
  { no: '10', type: 'number', min: 0, 'max': 99, name:'離床時間', explain: '[ 単位(秒) 1～98 0,99:無効 ]', required: true },
  { no: '11', type: 'number', min: 0, 'max': 9999, name:'連続離床時間', explain: '[ 単位(秒) 1～9998 0,9999:無効 ]', required: true },
  { no: '12', type: 'number', min: 0, 'max': 9999, name:'連続入浴時間', explain: '[ 単位(秒) 1～9998 0,9999:無効 ]', required: true },
  { no: '13', type: 'number', min: 0, 'max': 1, name:'在床時離床報知', explain: '[ 0:無 1:有 ]', required: true },
  { no: '14', type: 'number', min: 0, 'max': 99, name:'体動時間', explain: '[ 単位(秒) 1～98 0,99:無効 ]', required: true },
  { no: '15', type: 'number', min: 0, 'max': 99, name:'体動時間算出割合', explain: '[ 単位(%) 1～99 0:無効 ]', required: true },
  { no: '16', type: 'number', min: 0, 'max': 999, name:'体動異常時間', explain: '[ 単位(秒) 1～998 0,999:無効 ]', required: true },
  { no: '17', type: 'number', min: 0, 'max': 99, name:'体動異常時間算出割合', explain: '[ 単位(%) 1～99 0:無効 ]', required: true },
  { no: '18', type: 'number', min: 0, 'max': 999, name:'呼吸数計算倍率', explain: '[ 単位(10:0.1倍) 1～999 0:無効 ]', required: true },
  { no: '19', type: 'number', min: 0, 'max': 999, name:'脈拍数計算倍率', explain: '[ 単位(10:0.1倍) 1～999 0:無効 ]', required: true },
  { no: '20', type: 'number', min: 0, 'max': 4, name:'接点出力', explain: '[ 0:1秒接点 1:連続接点 2:出力無 3:2回アラート 4:2回アラート(注意後入浴) ]', required: true },
  { no: '21', type: 'number', min: 0, 'max': 999, name:'情報データ 送信間隔', explain: '[ 単位(10:1秒) 1～999 0:無効 ]', required: true },
  { no: '22', type: 'number', min: 0, 'max': 3, name:'グラフデータ 送信有無', explain: '[ 0:無 1:有 2:センサー波形出力 3:有(電源なし) ]', required: true },
  { no: '23', type: 'number', min: 0, 'max': 1, name:'LAN接続有無', explain: '[ 0:無 1:有 ]', required: true },
  { no: '24', type: 'number', min: 0, 'max': 1, name:'LAN接続リトライ有無', explain: '[ 0:無 1:有 ]', required: true },
  { no: '25', type: 'string', name:'MACアドレス', explain: '[ 変更不可 ]', disabled: true, required: true },
  { no: '26', type: 'string', name:'ゲートウェイアドレス', explain: '[ - ]', required: true, pattern: IP_PATTERN },
  { no: '27', type: 'string', name:'サブネットマスク', explain: '[ - ]', required: true, pattern: IP_PATTERN },
  { no: '28', type: 'string', name:'端末IPアドレス', explain: '[ - ]', required: true, pattern: IP_PATTERN },
  { no: '29', type: 'number', min: 0, 'max': 65535, name:'端末ポート番号', explain: '[ - ]', required: true },
  { no: '30', type: 'string', name:'ターゲットIP', explain: '[ - ]', required: true, pattern: IP_PATTERN },
  { no: '31', type: 'number', min: 0, 'max': 65535, name:'ターゲットIPポート番号', explain: '[ - ]', required: true },
  { no: '32', type: 'string', name:'AP名', explain: '[ - ]', required: true, pattern: STR_PATTERN },
  { no: '33', type: 'string', name:'APパスワード', explain: '[ - ]', required: true, pattern: STR_PATTERN },
  { no: '34', type: 'string', name:'WLAN接続先IP', explain: '[ - ]', required: true, pattern: IP_PATTERN },
  { no: '35', type: 'number', min: 0, 'max': 65535, name:'WLAN接続先PORT', explain: '[ - ]', required: true },
  { no: '36', type: 'number', min: 0, 'max': 1, name:'WLAN接続有無', explain: '[ 0:無 1:有 ]', required: true },
  { no: '37', type: 'number', min: 0, 'max': 1, name:'WLAN接続リトライ有無', explain: '[ 0:無 1:有 ]', required: true },
];
const DISABLE_BR_RANGE_SEC = 999;
const DISABLE_AP_RANGE_SEC = 999;
const DISABLE_BOUT_TIM_SEC = 99;
const DISABLE_BOUTIN_TIM_SEC = 9999;
const DISABLE_MOV_SEC = 99;
const DISABLE_EMOV_SEC = 999;

export default {
  components: {
    Button,
    SensorSettingItem,
    VueCustomScrollbar,
    SensorSendConfirmDialog
  },
  props: {
    user: {
      type: UserEntity,
      default: new UserEntity()
    },
  },
  data () {
    return {
      memo: '',
      itemList: [],
      processing: false,
    };
  },
  methods: {
    showSensorSettingUpdateDialog () {
      if (this.validate()) {
        // 送信用データを取得
        let sendData = this.createSendData();
        let data = {
          userId: this.user.id,
          gatewayId: this.user.gateway.id,
          sensorId: this.bedSensorInfo.id,
          setting: sendData
        };
        this.$refs.sensorSettingUpdateDialog.show(data);
      }
    },
    createSendData () {
      let setting = {};
      if (this.isChangeMemo) {
        setting['memo'] = this.memo;
      }
      let self = this;
      _.each(this.diff, function (item) {
        let itemNo = item.no;
        switch (itemNo) {
          case '01':
            setting['unitNo'] = item.value;
            break;
          case '02':
            setting['func'] = item.value;
            break;
          case '03':
          case '04':
          case '05':
            // brRange
            if (_.isUndefined(setting['brRange'])) {
              setting['brRange'] = { ...self.target.brRange };
            }
            switch (itemNo) {
              case '03':
                setting.brRange.min = item.value;
                break;
              case '04':
                setting.brRange.max = item.value;
                break;
              case '05':
                if (item.value === DISABLE_BR_RANGE_SEC) {
                  // 無効値はフラグのみ
                  setting.brRange.secFlg = false;
                }
                else {
                  // 有効値はフラグと値
                  setting.brRange.secFlg = true;
                  setting.brRange.sec = item.value;
                }
                break;
            }
            break;
          case '06':
          case '07':
          case '08':
            // apRange
            if (_.isUndefined(setting['apRange'])) {
              setting['apRange'] = { ...self.target.apRange };
            }
            switch (itemNo) {
              case '06':
                setting.apRange.min = item.value;
                break;
              case '07':
                setting.apRange.max = item.value;
                break;
              case '08':
                if (item.value === DISABLE_AP_RANGE_SEC) {
                  // 無効値はフラグのみ
                  setting.apRange.secFlg = false;
                }
                else {
                  // 有効値はフラグと値
                  setting.apRange.secFlg = true;
                  setting.apRange.sec = item.value;
                }
                break;
            }
            break;
          case '09':
          case '10':
            // boutTim
            if (_.isUndefined(setting['boutTim'])) {
              setting['boutTim'] = { ...self.target.boutTim };
            }
            switch (itemNo) {
              case '09':
                if (item.value === DISABLE_BOUT_TIM_SEC) {
                  // 無効値はフラグのみ
                  setting.boutTim.sec1Flg = false;
                }
                else {
                  // 有効値はフラグと値
                  setting.boutTim.sec1Flg = true;
                  setting.boutTim.sec1 = item.value;
                }
                break;
              case '10':
                if (item.value === DISABLE_BOUT_TIM_SEC) {
                  // 無効値はフラグのみ
                  setting.boutTim.sec2Flg = false;
                }
                else {
                  // 有効値はフラグと値
                  setting.boutTim.sec2Flg = true;
                  setting.boutTim.sec2 = item.value;
                }
                break;
            }
            break;
          case '11':
          case '12':
          case '13':
            // boutinCtim
            if (_.isUndefined(setting['boutinCtim'])) {
              setting['boutinCtim'] = { ...self.target.boutinCtim };
            }
            switch (itemNo) {
              case '11':
                if (item.value === DISABLE_BOUTIN_TIM_SEC) {
                  // 無効値はフラグのみ
                  setting.boutinCtim.outMinFlg = false;
                }
                else {
                  // 有効値はフラグと値
                  setting.boutinCtim.outMinFlg = true;
                  setting.boutinCtim.outMin = item.value;
                }
                break;
              case '12':
                if (item.value === DISABLE_BOUTIN_TIM_SEC) {
                  // 無効値はフラグのみ
                  setting.boutinCtim.inMinFlg = false;
                }
                else {
                  // 有効値はフラグと値
                  setting.boutinCtim.inMinFlg = true;
                  setting.boutinCtim.inMin = item.value;
                }
                break;
              case '13':
                setting.boutinCtim.alt = item.value;
                break;
            }
            break;
          case '14':
          case '15':
            // mov
            if (_.isUndefined(setting['mov'])) {
              setting['mov'] = { ...self.target.mov };
            }
            switch (itemNo) {
              case '14':
                if (item.value === DISABLE_MOV_SEC) {
                  // 無効値はフラグのみ
                  setting.mov.secFlg = false;
                }
                else {
                  // 有効値はフラグと値
                  setting.mov.secFlg = true;
                  setting.mov.sec = item.value;
                }
                break;
              case '15':
                setting.mov.rate = item.value;
                break;
            }
            break;
          case '16':
          case '17':
            // emov
            if (_.isUndefined(setting['emov'])) {
              setting['emov'] = { ...self.target.emov };
            }
            switch (itemNo) {
              case '16':
                if (item.value === DISABLE_EMOV_SEC) {
                  // 無効値はフラグのみ
                  setting.emov.secFlg = false;
                }
                else {
                  // 有効値はフラグと値
                  setting.emov.secFlg = true;
                  setting.emov.sec = item.value;
                }
                break;
              case '17':
                setting.emov.rate = item.value;
                break;
            }
            break;
          case '18':
            setting['bcal'] = { mag: item.value };
            break;
          case '19':
            setting['acal'] = { mag: item.value };
            break;
          case '20':
            setting['contactOut'] = item.value;
            break;
          case '21':
            setting['idatItim'] = item.value;
            break;
          case '22':
            setting['gdatEna'] = item.value;
            break;
          case '23':
          case '24':
          case '26':
          case '27':
          case '28':
          case '29':
          case '30':
          case '31':
            if (_.isUndefined(setting['lan'])) {
              setting['lan'] = {};
            }
            switch (itemNo) {
              case '23':
                setting.lan['ena'] = item.value;
                break;
              case '24':
                setting.lan['retry'] = item.value;
                break;
              case '26':
                setting.lan['gateway'] = item.value;
                break;
              case '27':
                setting.lan['subnet'] = item.value;
                break;
              case '28':
                setting.lan['uip'] = item.value;
                break;
              case '29':
                setting.lan['uport'] = item.value;
                break;
              case '30':
                setting.lan['tip'] = item.value;
                break;
              case '31':
                setting.lan['tport'] = item.value;
                break;
            }
            break;
          case '32':
          case '33':
          case '34':
          case '35':
          case '36':
          case '37':
            if (_.isUndefined(setting['wlan'])) {
              setting['wlan'] = {};
            }
            switch (itemNo) {
              case '32':
                setting.wlan['ssid'] = item.value;
                break;
              case '33':
                setting.wlan['pass'] = item.value;
                break;
              case '34':
                setting.wlan['tip'] = item.value;
                break;
              case '35':
                setting.wlan['tport'] = item.value;
                break;
              case '36':
                setting.wlan['ena'] = item.value;
                break;
              case '37':
                setting.wlan['retry'] = item.value;
                break;
            }
            break;
          default:
        }
      });
      return setting;
    },
    validate () {
      let diff = this.diff;
      let invalid = false;
      let self = this;
      _.each(this.itemList, function (item) {
        item.invalid = false;
      });
      _.each(diff, function (item) {
        // NGがfalse
        let result = !self.checkValue(item);
        if (!invalid) {
          // falseなら代入、一度でもtrueになれば
          invalid = result;
        }
      });
      // 無効ならvalidate NG
      return !invalid;
    },
    hide () {
      this.$modal.hide('user-setting-dialog');
    },
    checkValue (item) {
      let itemNo = item.no;
      let result = true;
      // 共通チェック
      if (item.value === '') {
        // 空欄
        item.invalid = true;
        return false;
      }

      if (item.type === 'number') {
        if (item.min > item.value || item.max < item.value) {
          // 数値範囲外
          item.invalid = true;
          return false;
        }
      }
      switch (itemNo) {
        case '03':
          // brRange
          let brRangeMax = _.find(this.itemList, function (obj) {
            return obj.no === '04';
          });
          if (!(brRangeMax.value > item.value)) {
            item.invalid = true;
            return false;
          }
          break;
        case '04':
          let brRangeMin = _.find(this.itemList, function (obj) {
            return obj.no === '03';
          });
          if (!(brRangeMin.value < item.value)) {
            item.invalid = true;
            return false;
          }
          break;
        case '06':
          // apRange
          let apRangeMax = _.find(this.itemList, function (obj) {
            return obj.no === '07';
          });
          if (!(apRangeMax.value > item.value)) {
            item.invalid = true;
            return false;
          }
          break;
        case '07':
          let apRangeMin = _.find(this.itemList, function (obj) {
            return obj.no === '06';
          });
          if (!(apRangeMin.value < item.value)) {
            item.invalid = true;
            return false;
          }
          break;
        case '26':
        case '27':
        case '28':
        case '30':
        case '34':
          // IPv4形式
          if (!this.ipAddressCheck(item.value)) {
            item.invalid = true;
            return false;
          }
          break;
        case '32':
        case '33':
          // 半角英数字とハイフン
          if (!this.strCheck(item.value)) {
            item.invalid = true;
            return false;
          }
          break;
        default:
          // 以下項目は独自判定無し
          // case "01":
          // case "02":
          // case "05":
          // case "08":
          // case "09":
          // case "10":
          // case "11":
          // case "12":
          // case "13":
          // case "14":
          // case "15":
          // case "16":
          // case "17":
          // case "18":
          // case "19":
          // case "20":
          // case "21":
          // case "22":
          // case "23":
          // case "24":
          // case "25":
          // case "29":
          // case "31":
          // case "35":
          // case "36":
          // case "37":
          break;
      }
      item.invalid = false;
      return result;
    },
    getSettingValue (itemNo) {
      switch (itemNo) {
        case '01':
          return this.target.unitNo;
        case '02':
          return this.target.func;
        case '03':
          // brRange
          return this.target.brRange.min;
        case '04':
          return this.target.brRange.max;
        case '05':
          return this.target.brRange.secFlg ? this.target.brRange.sec : DISABLE_BR_RANGE_SEC;
        case '06':
          // apRange
          return this.target.apRange.min;
        case '07':
          return this.target.apRange.max;
        case '08':
          return this.target.apRange.secFlg ? this.target.apRange.sec : DISABLE_AP_RANGE_SEC;
        case '09':
          // boutTim
          return this.target.boutTim.sec1Flg ? this.target.boutTim.sec1 : DISABLE_BOUT_TIM_SEC;
        case '10':
          return this.target.boutTim.sec2Flg ? this.target.boutTim.sec2 : DISABLE_BOUT_TIM_SEC;
        case '11':
          // boutinCtim
          return this.target.boutinCtim.outMinFlg ? this.target.boutinCtim.outMin : DISABLE_BOUTIN_TIM_SEC;
        case '12':
          return this.target.boutinCtim.inMinFlg ? this.target.boutinCtim.inMin : DISABLE_BOUTIN_TIM_SEC;
        case '13':
          return this.target.boutinCtim.alt;
        case '14':
          // mov
          return this.target.mov.secFlg ? this.target.mov.sec : DISABLE_MOV_SEC;
        case '15':
          return this.target.mov.rate;
        case '16':
          // emov
          return this.target.emov.secFlg ? this.target.emov.sec : DISABLE_EMOV_SEC;
        case '17':
          return this.target.emov.rate;
        case '18':
          return this.target.bcal.mag;
        case '19':
          return this.target.acal.mag;
        case '20':
          return this.target.contactOut;
        case '21':
          return this.target.idatItim;
        case '22':
          return this.target.gdatEna;
        case '23':
          return this.target.lan.ena;
        case '24':
          return this.target.lan.retry;
        case '25':
          return this.target.lan.mac;
        case '26':
          return this.target.lan.gateway;
        case '27':
          return this.target.lan.subnet;
        case '28':
          return this.target.lan.uip;
        case '29':
          return this.target.lan.uport;
        case '30':
          return this.target.lan.tip;
        case '31':
          return this.target.lan.tport;
        case '32':
          return this.target.wlan.ssid;
        case '33':
          return this.target.wlan.pass;
        case '34':
          return this.target.wlan.tip;
        case '35':
          return this.target.wlan.tport;
        case '36':
          return this.target.wlan.ena;
        case '37':
          return this.target.wlan.retry;
      }
    },
    ipAddressCheck (value) {
      // IPv4形式
      let result = value.match(/^(([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([1-9]?[0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/);
      if (!result) {
        return false;
      }
      return true;
    },
    strCheck (value) {
      // 半角英数字とハイフン
      let result = value.match(/^[\da-zA-Z-]+$/);
      if (!result) {
        return false;
      }
      return true;
    },
    getTarget () {
      if (_.isNull(this.user)) return new BedSensorSettingEntity();
      if (this.user.sensorSettings.length === 0) return new BedSensorSettingEntity();
      let target = _.find(this.user.sensorSettings, (obj) => {
        return obj.code === Constants.SENSOR_BED;
      });
      if (_.isUndefined(target)) {
        return new BedSensorSettingEntity();
      }
      return target.setting;
    }
  },
  computed: {
    bedSensorInfo () {
      let target = _.find(this.user.sensors, (sensor) => {
        // ベッドセンサのみ設定できる
        return sensor.type === Constants.SENSOR_BED;
      });
      return target;
    },
    target () {
      return this.getTarget();
    },
    diff () {
      let diff = [];
      let self = this;
      _.each(ITEM_LIST, function (item, i) {
        if (self.itemList[i].value !== self.oldItemList[i].value) {
          diff.push(self.itemList[i]);
        }
      });

      return diff;
    },
    isChange () {
      if (this.diff.length === 0 && !this.isChangeMemo) {
        return false;
      }
      else {
        return true;
      }
    },
    isChangeMemo () {
      if (this.memo === this.oldMemo) {
        return false;
      }
      else {
        return true;
      }
    },
    oldItemList () {
      let self = this;
      let itemList = [];
      _.each(ITEM_LIST, function (item) {
        let obj = { value: self.getSettingValue(item.no), ...item };
        itemList.push(obj);
      });
      return itemList;
    },
    oldMemo () {
      return this.target.memo;
    },
  },
  created () {
    let self = this;
    _.each(ITEM_LIST, function (item) {
      let obj = { value: self.getSettingValue(item.no), ...item, invalid: false };
      self.itemList.push(obj);
    });
    this.memo = this.target.memo;
  }
};
</script>

<style scoped>
.jsp-tab-content {
  display: flex;
  height: 370px;
  padding: 12px;
  align-items: center;
}
.jsp-button + .jsp-button {
  margin-left: 8px;
}
.jsp-button {
  min-width: 100px;
}
.sensor-setting-tab {
  margin: 5px;
}
.memo {
  padding: 10px;
}
.buttons {
  padding: 10px;
  justify-content: center;
}
.setting-area {
  height: 350px;
  box-shadow: 0px 6px 8px -4px rgba(0,0,0,0.2) inset;
  background-color: white;
  padding-top: 0px;
  padding-left: 0px;
  padding-right: 0px;
  overflow: hidden;
}

.other-area {
  height: 350px;
  padding-right: 0px;
}
.jsp-tab-content textarea {
  resize: none !important;
}

.jsp-setting-list > .jsp-setting-item {
  padding-bottom: 10px;
}
.jsp-setting-list {
  position: relative;
  height: 350px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 10px;
}
</style>
